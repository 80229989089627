import React from "react";
import { useState } from "react";
import "../assets/css/spinner.scss";

export default function LoadingSpinner() {
  const [imageSrc, setImageSrc] = useState('');

  React.useEffect(() => {    
    setImageSrc("/loader/loader.gif");    
  }, []);

  return (
    <div className="spinner-container">
      <div className="spinner-padding">
        <img id="licenceDocumentFileImage" src={imageSrc} width="50" height="50" />
      </div>
    </div>
  )
}