/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-undef */

import axios from "axios";
import React from "react";
import { HttpHeaders } from "../shared/HttpHeaders";
import ExpiredChartTable from "./ExpiredChartTable";
import ReactECharts from 'echarts-for-react';
import { H2, H5 } from "@tag/tag-components-react-v4";
import { NoEntryFilled } from "@tag/tag-icons";
import "../assets/css/chart.scss";
import User from "../components/User";

interface IChartData {
  value: number;
  name: string;
}

function ExpiredChart({
  chartName,
  chartAPIName,
  chartDetailAPIName,
  fromDashBoard,
  openDashboardScreen,
  SetopenDashboardScreen,
}: any) {
  const Headers = HttpHeaders();
  const [showChart, setshowChart] = React.useState(false);
  const [chartData, setchartData] = React.useState<IChartData[]>([]);
  const [legendvalues, setlegendvalues] = React.useState<string[]>([]);
  const colorvalues = ["#AD230A", "#D82C0D", "#EB9686", "#F7D5CF"];
  const [openFilterUserScreen, setopenFilterUserScreen] = React.useState(false);

  const onChartClick = (e: any) => {
    if (e.seriesName == "Expired") {
      SetopenDashboardScreen(false);
      fromDashBoard("Expired", e.data["name"], 0, 0);
    }
  };

  React.useEffect(() => {
    bindChartData();
  }, []);

  const bindChartData = () => {
    setchartData([]);
    axios
      .get(`Admin/` + chartAPIName, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                const legendValuesArray: string[] = [];
                for (let i = 0; i < response.data.data.length; i++) {
                  legendValuesArray.push(response.data.data[i]["name"]);
                }

                setlegendvalues(legendValuesArray);
                setchartData(response.data.data);
                setshowChart(true);
              }
            }
          }
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind expired chart data!");
      });
  };

  const onEvents = {
    'click': onChartClick,
  }

  return (
    <div>
      {openDashboardScreen ? (
        <div>
          {showChart ? (
            <>
              <ReactECharts
                style={{ height: '250px', width: '350px' }}
                onEvents={onEvents}
                option={{
                  shadow: true,
                  tooltip: {
                    trigger: "item",
                    formatter: "{b} <br/> {c} ({d}%)",
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  title: {
                    top: 10,
                    text: chartName,
                    left: "center",
                    textStyle: {
                      fontSize: 14,
                    },
                  },
                  legend: {
                    bottom: 10,
                    left: "center",
                    data: legendvalues,
                    textStyle: {
                      fontSize: 10,
                    },
                  },
                  color: colorvalues,
                  series: [
                    {
                      name: chartName,
                      type: "pie",
                      radius: ["35%", "65%"],
                      avoidLabelOverlap: false,
                      labelLine: {
                        length: 20,
                      },
                      emphasis: {
                        itemStyle: {
                          borderWidth: 0,
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: "rgba(0, 0, 0, 0.5)",
                        },
                      },
                      label: {
                        formatter: "{c}",
                        position: "inside",
                      },
                      data: chartData,
                    },
                  ],
                }}
              />
              <ExpiredChartTable apiName={chartDetailAPIName} />
            </>
          ) : (
            <>
              <div className="chartGrid">
                <label className="chartName chartHeader">{chartName}</label>
                <div className="chartTable ">
                  <NoEntryFilled size="extraLarge" style={{ height: "100px", width: "100px", marginBottom: "15px" }} />
                  <H2>No items available</H2>
                </div>
              </div>
            </>
          )}
        </div>
      ) : null}
      {openFilterUserScreen ? <User /> : null}
    </div>
  );
}
export default ExpiredChart;
