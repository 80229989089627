import { useState } from "react";
import { FormField, FormLayout, Input, Modal, Textarea } from "@tag/tag-components-react-v4";
import "../../../assets/css/userDetail.scss";

function LicenceDetails({
  openLicenceScreen,
  licencevehicleData,
  SetopenLicenceScreen,
  licencedob,
  issuedate,
  expirydate,
  datecometoUK,
  ismanuallyaddlicence,
  fileName,
}: any) {
  const [imageSrc, setImageSrc] = useState('');
  const [showLicenceImageModel, setshowLicenceImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];

  const onCancelButtonClick = (e: any) => {
    SetopenLicenceScreen(false);
  };

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    fileName = licencevehicleData.licencedocument.doC_D_FILE_NAME;
    fileType = licencevehicleData.licencedocument.doC_D_FILE_CONTENT_TYPE;
    fileData = licencevehicleData.licencedocument.doC_D_FILE_DATA;

    var fileBinaryString = atob(fileData);
    var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
    for (var i = 0; i < fileBinaryString.length; i++) {
      var ascii = fileBinaryString.charCodeAt(i);
      dowloadFileBytes[i] = ascii;
    }

    var file = new Blob([dowloadFileBytes], {
      type: fileType,
    });

    if (allowedFileTypes.includes(fileType)) {
      var motFileObjectWithFileTypeURL = URL.createObjectURL(file);
      setImageSrc(motFileObjectWithFileTypeURL);
      setshowLicenceImageModel(true);
    } else {
      var motFileObjectURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = motFileObjectURL;
      link.download = fileName;
      link.click();
    }
  }

  return (
    <div>
      {ismanuallyaddlicence ? (
        <>
          <div>
            <Modal
              visible={openLicenceScreen}
              accent="teal"
              heading="Licence Details"
              cancelButtonProps={{ text: "Cancel" }}
              primaryButtonProps={{ buttonStyle: { display: "none" } }}
              onClose={() => { SetopenLicenceScreen(false); }}
              onCancelButtonClick={onCancelButtonClick}
            >
              <div className="lic-Content">
                <FormLayout columns={1} rows={6}>
                  <FormField
                    label="Country"
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<Input name="lic-country-name" value={licencevehicleData.countrY_NAME} style={{ width: '100%' }} />}
                    disabled
                  />

                  <FormField
                    label="Date when you come to UK"
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<Input name="lic-come-to-UK" value={datecometoUK} style={{ width: '100%' }} />}
                    disabled
                  />

                  <FormField
                    label="Licence No."
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<Input name="lic-no" value={licencevehicleData.licenceno} style={{ width: '100%' }} />}
                    disabled
                  />

                  <FormField
                    label="Issue Date"
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<Input name="lic-IssueDate" value={issuedate} style={{ width: '100%' }} />}
                    disabled
                  />

                  <FormField
                    label="Expiry Date"
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<Input name="lic-ExpiryDate" value={expirydate} style={{ width: '100%' }} />}
                    disabled
                  />

                  <FormField
                    label="Driving Licence Copy"
                    labelProps={{ style: { fontWeight: '500' } }}
                    editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="insuranceDocument">{fileName}</a>}
                    disabled
                  />
                </FormLayout>
              </div>
            </Modal>
          </div>
        </>
      ) : <>
        <div>
          <Modal
            visible={openLicenceScreen}
            accent="teal"
            heading="Licence Details"
            cancelButtonProps={{ text: "Cancel" }}
            primaryButtonProps={{ buttonStyle: { display: "none" } }}
            onClose={() => { SetopenLicenceScreen(false); }}
            onCancelButtonClick={onCancelButtonClick}
          >
            <div className="lic-Content">
              <FormLayout columns={2} rows={5}>
                <FormField
                  label="Licence No."
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-no" value={licencevehicleData.licenceno} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Date Of Birth"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-Date-Of-Birth" value={licencedob} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Name"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-Name" value={licencevehicleData.name} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Surname"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-Surname" value={licencevehicleData.surname} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Issue Date"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-IssueDate" value={issuedate} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Expiry Date"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-ExpiryDate" value={expirydate} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Categories"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="lic-Categories" value={licencevehicleData.categories} style={{ width: '100%' }} />}
                  disabled
                  columnSpan={2}
                />

                <FormField
                  label="Address"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Textarea name="lic-Address" rows={2} resize="none" value={licencevehicleData.address} style={{ width: '100%' }} />}
                  disabled
                  columnSpan={2}
                />
              </FormLayout>
            </div>
          </Modal>
        </div>
      </>}

      {
        showLicenceImageModel ? (
          <>
            <div>
              <Modal
                visible
                accent="teal"
                heading="Licence Document"
                cancelButtonProps={{ buttonStyle: { display: "none" } }}
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onClose={() => { setshowLicenceImageModel(false); }}
                scroll="content"
              >
                <div style={{ textAlign: "center" }}>
                  <img id="licenceDocumentFileImage" alt="Licence Document" src={imageSrc} />
                </div>
              </Modal>
            </div>
          </>
        ) : null
      }
    </div >
  );
}
export default LicenceDetails;
