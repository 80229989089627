import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import { defineCustomElements as defineCustomElements_v1 } from "@tag/tag-components";

// import {
//   applyPolyfills,
//   defineCustomElements,
// } from "@tag/tag-components-v3/loader";

// applyPolyfills().then(() => {
//   defineCustomElements();
// });

// defineCustomElements_v1(window);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

export default App;
