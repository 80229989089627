/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ToastMessagePosition, AlertMessageType } from "../../assets/shared/types"
import React, { useState, useEffect } from "react";
import event from "./eventBus";
import { Alert, ToastContainer, useToasts } from "@tag/tag-components-react-v4";

export const Notify = (callBackMain: any) => {
  const [content, setContent] = useState(""); // Content display in dialogue box
  const [showModal, setShowModal] = useState(false); // confirmation box show/hide
  const [showAlertPositon, setShowAlertPositon] = useState<ToastMessagePosition>("bottom-left"); // confirmation box show/hide
  const [notifyModelOpenFrom, setnotifyModelOpenFrom] = React.useState<string>("timeline");
  const toastContainerId = 'intro-demo';
  const { toast } = useToasts(toastContainerId);

  const setMessage = (
    message: any,
    dialogueType: any,
    modelType: AlertMessageType,
    messageHeader: any,
    durationVal: number,
    toastPosition: ToastMessagePosition,
    notifyModelOpenFrom: string
  ) => {
    setContent(message);
    setShowModal(true);
    if (!dialogueType) {
      setShowModal(false);
      setShowAlertPositon(toastPosition);
      setShowModal(true);
    }
    setnotifyModelOpenFrom(notifyModelOpenFrom);

    toast(
      <Alert
        heading={messageHeader}
        type={modelType}
        closeTransition="fade"
        showDuration={durationVal}
        closeable={false}>
        {message}
      </Alert>,
      {
        autoClose: durationVal,
        dismissable: false
      }
    );
  };

  const clearMessage = () => {
    setContent("");
    setShowModal(false);
    if (notifyModelOpenFrom !== "")
      callBackMain.callBackMain(notifyModelOpenFrom + "Open");
  };

  useEffect(() => {
    event.on("showModal", setMessage).on("clearAllMessage", clearMessage);
  }, [clearMessage]);

  if (showModal) {
    return (
      <>
        <ToastContainer position={showAlertPositon} id={toastContainerId} />
      </>
    );
  };
  return null;
}