import Menu from "./components/Menu";
import "bootstrap/dist/css/bootstrap.min.css";
import { IdentityProvider, LoginCallback } from "@workspace/identity-react";
import { IframePidgetProvider, useIframePidget } from "@workspace/utils-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Notify } from "./components/Notify/Notify";

const App = () => {
  const iframePidget = useIframePidget<any>();
  const callBackMain = () => {
    console.log("call");
  }
  
  if (
    iframePidget &&
    iframePidget.settings &&
    iframePidget.settings.identityUrl
  ) {
    return (
      <IframePidgetProvider iframePidget={iframePidget}>
        <IdentityProvider
          authority={iframePidget.settings.identityUrl}
          clientId={iframePidget.settings.appClientId}
          redirectUri={`${window.location.origin}/login/callback`}
          scope="openid email profile policy access.widget.api access.dutyofcare.api"
          workspaceUrl={iframePidget.settings.workspaceUrl}
        >
          <Menu />
          <Notify callBackMain={callBackMain} />
        </IdentityProvider>
      </IframePidgetProvider>
    );
  } else {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/login/callback">
            <LoginCallback />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
};

export default App;