/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-useless-computed-key */

import { DataTable, Checkbox, Pager, Confirm, P, DropDownNative, Modal, FormLayout, FormField, Input, DatePicker } from "@tag/tag-components-react-v4";
import axios from "axios";
import React, { useState, useRef } from "react";
import UserDetails from "../components/UserDetails/UserDetails";
import { IUsersFilter, IUsers, IUsersDetails, ITagsValue } from "../core/view-Model/users.model";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./LoadingSpinner";
import { NotifyPublisher } from "./Notify/NotifyPublisher";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { PidgetSettings } from "../PidgetSettings";
import { Button, SquareButton, TabContent, TabHeader, TabHeaders, Tabs } from "@tag/tag-components-react-v4";
import { CircleTickFilled, MicrosoftExcelFilled, SwitchesFilled, ViewFilled } from "@tag/tag-icons";
import "../../src/assets/css/userDetail.scss";
import "../../src/assets/css/user.scss";
import "../../src/assets/css/common.scss";

function User({
  openUserScreen,
  SetopenUserScreen,
  chartName,
  chartValue,
  levelHigh,
  levelLow,
  userStatus,
}: any) {
  const Headers = HttpHeaders();
  const pageSize = 10;
  const [openDetailScreen, SetopenopenDetailScreen] = useState(false);
  const [openuserScreen, SetopenopenuserScreen] = useState(false);
  const [openUserTabScreen, SetopenUserTabScreen] = useState(true);
  const [openAlluserTabScreen, SetopenAlluserTabScreen] = useState(false);
  const [selectedTab, setselectedTab] = useState("DOCUser");
  const approvedColor = '{"margin":"0 auto", "background-color": "#68B590","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  const pendingColor = '{"margin":"0 auto", "background-color": "#FF9430","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  const rejectcolor = '{"margin":"0 auto", "background-color": "#EEF0F2","color": "black","padding": "3px","border-radius": "15px", "display": "block", "width": "80px","text-align":"center"}';
  const organizationUserRolecolor = '{"margin":"0 auto"}';
  const [allUsersData, setAllUsersData] = React.useState<IUsers[]>([]); //Workspace User data
  const allUsersCheckboxDetails = useRef<IUsers[]>([]);
  const [usersDetailsData, setUsersDetailsData] = React.useState<IUsersDetails[]>([]); // DOC user data
  const [isLoading, setIsLoading] = useState(false);
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";
  const tag1HeaderValue = useRef("");
  const [tag1Values, settag1Values] = React.useState<ITagsValue[]>([]);
  const tag2HeaderValue = useRef("");
  const [tag2Values, settag2Values] = React.useState<ITagsValue[]>([]);
  const tag3HeaderValue = useRef("");
  const [tag3Values, settag3Values] = React.useState<ITagsValue[]>([]);
  const [isdisabled, setdisabled] = useState<boolean>(true);
  const [showArchiveUserConfirmationPopup, setshowArchiveUserConfirmationPopup] = useState(false);
  const [showStopAutoCheckConfirmationDialogue, setStopAutoCheckConfirmationDialogue] = useState(false);
  const accessUserColor = { margin: "0 auto", backgroundColor: "#68B590", color: "black", padding: "3px", borderRadius: "15px", display: "block", width: "80px", textAlign: "center" };
  const notUsedUsercolor = { margin: "0 auto", backgroundColor: "#FF9430", color: "black", padding: "3px", borderRadius: "15px", display: "block", width: "80px", textAlign: "center" };
  const noAccessUserColor = { margin: "0 auto", backgroundColor: "#EEF0F2", color: "black", padding: "3px", borderRadius: "15px", display: "block", width: "80px" };

  const [localUserFilterData, setlocalUserFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user filter data

  const [localUserApplyFilterData, setlocalUserApplyFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user apply filter data

  const [organizationUserFilterData, setorganizationUserFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user filter data

  const [organizationUserApplyFilterData, setorganizationUserApplyFilterData] =
    React.useState<IUsersFilter>({
      Name: "",
      VehicleRegistrationNumber: "",
      LicenceNo: "",
      VehicleLicenceExpiryDate: null,
      VehicleTaxDueDate: null,
      VehicleMotExpiryDate: null,
      VehicleInsuranceExpiryDate: null,
      JobTitle: "",
      Office: "",
      Department: "",
      tag1Value: "",
      tag2Value: "",
      tag3Value: "",
      Status: "",
      ChartFilter: false,
      ChartName: "",
      ChartValue: "",
      ChartLevelHigh: 0,
      ChartLevelLow: 0,
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    }); // DOC user apply filter data

  const [openDOCUsersFilterScreen, SetOpenDOCUsersFilterScreen] = useState(false);
  const OnDOCUsersFilterOpened = (e: any) => { };

  const OnDOCUsersFilterClick = (e: any) => {
    SetOpenDOCUsersFilterScreen(true);
  };

  const [
    openOrganizationUsersFilterScreen,
    SetOpenOrganizationUsersFilterScreen,
  ] = useState(false);
  const OnOrganizationUsersFilterOpened = (e: any) => { };

  const OnOrganizationUsersFilterClick = (e: any) => {
    SetOpenOrganizationUsersFilterScreen(true);
  };

  const [docUsersFilterCount, setdocUsersFilterCount] = React.useState('0');
  const [organizationUsersFilterCount, setorganizationUsersFilterCount] = React.useState('0');

  React.useEffect(() => {
    if (chartName) {
      localUserApplyFilterData.ChartFilter = true;
      localUserApplyFilterData.ChartName = chartName;
      localUserApplyFilterData.ChartValue = chartValue;
      localUserApplyFilterData.ChartLevelHigh = levelHigh;
      localUserApplyFilterData.ChartLevelLow = levelLow;
      setselectedTab("DOCUser");
      LoadUsersDetails(1);
    } else if (userStatus) {
      organizationUserApplyFilterData.Status = userStatus;
      onOrganizationUserTab();
      setselectedTab("OrganisationUser");
    } else {
      setselectedTab("DOCUser");
      GetTagsDetails();
    }
  }, [chartName, userStatus, chartValue, levelHigh, levelLow]);

  const GetTagsDetails = () => {
    setIsLoading(true);
    axios.get(`User/GetAllTagsAndValues`, Headers).then((response) => {
      if (response !== null && response !== undefined) {
        if (response.data !== null && response.data !== undefined) {
          if (response.data.data !== null && response.data.data !== undefined) {
            for (var i = 0; i < response.data.data.length && i < 3; i++) {
              if ((i + 1) === 1) {
                tag1HeaderValue.current = response.data.data[i].name;
              } else if ((i + 1) === 2) {
                tag2HeaderValue.current = response.data.data[i].name;
              } else {
                tag3HeaderValue.current = response.data.data[i].name;
              }

              if (response.data.data[i].tagItems !== null && response.data.data[i].tagItems !== undefined && response.data.data[i].tagItems.length > 0) {
                let tagValues = [];
                tagValues.push({ label: "", value: "" });
                for (var j = 0; j < response.data.data[i].tagItems.length; j++) {
                  tagValues.push({
                    label: response.data.data[i].tagItems[j].name,
                    value: response.data.data[i].tagItems[j].id
                  });
                }
                if (tagValues.length > 0) {
                  if ((i + 1) === 1) {
                    settag1Values(tagValues);
                  } else if ((i + 1) === 2) {
                    settag2Values(tagValues);
                  } else {
                    settag3Values(tagValues);
                  }
                }
              }
            }
            LoadUsersDetails(1);
          }
          else {
            LoadUsersDetails(1);
          }
        } else {
          LoadUsersDetails(1);
        }
      } else {
        LoadUsersDetails(1);
      }
    }).catch((ex) => {
      console.log("Getting some API error when getting tag values!");
      LoadUsersDetails(1);
      setIsLoading(false);
    });
  };

  function onUserRowClick(newValue: any) {
    if (typeof (newValue) == "object") {
      let row = {};
      let detail = {};
      row = newValue.row.original;
      detail = { row }
      newValue = { detail }
      LoadUsersDetails(1);
      SetopenopenuserScreen(newValue);
    } else {
      SetopenopenuserScreen(newValue);
      LoadUsersDetails(1);
    }
  }
  const userRowchecked = (e: any, checkValue: any) => {
    if (checkValue === true && e.row.original !== undefined) {
      const allUsersCheckboxDetailsIndex = allUsersCheckboxDetails.current.filter(x => x.userId === e.row.original.userId);
      if (allUsersCheckboxDetailsIndex.length === 0) {
        allUsersCheckboxDetails.current.push(e.row.original);
      }
    } else {
      const allUsersCheckboxDetailsIndex = allUsersCheckboxDetails.current.indexOf(e.row.original);
      allUsersCheckboxDetails.current.splice(allUsersCheckboxDetailsIndex, 1);
    }
    if (allUsersCheckboxDetails.current.length > 0) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  };

  const OnTabChange = (e: any) => {
    if (e === "DOCUser") {
      LoadUsersDetails(1);
      SetopenUserTabScreen(true);
      SetopenAlluserTabScreen(false);
      setselectedTab("DOCUser");
    } else if (e === "OrganisationUser") {
      onOrganizationUserTab();
      setselectedTab("OrganisationUser");
    }
  };

  const onOrganizationUserTab = () => {
    LoadAllUsers(1);
    SetopenAlluserTabScreen(true);
    SetopenUserTabScreen(false);
  };

  const OnStartAutoCheckedClick = (e: any) => {
    SetStartAutocheckData(allUsersCheckboxDetails.current);
  };

  const OnStopAutoCheckedClick = (e: any) => {
    if (allUsersCheckboxDetails.current.length > 1) {
      setStopAutoCheckConfirmationDialogue(false);
    } else {
      setStopAutoCheckConfirmationDialogue(true);
    }
    setshowArchiveUserConfirmationPopup(true)
  };

  const SetStopAutocheckData = (userCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetStopAutocheckData`,
        userCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved.'), false, 'success', 'Success', '');
              SetopenUserTabScreen(false);
              LoadUsersDetails(1);
              SetopenUserTabScreen(true);
              setUsersDetailsData(response.data.data);
              UpdateUserAvtar();
              setIsLoading(false);
            }
            else {
              setIsLoading(false);
            }
          }
        }
      });
  };

  const SetStartAutocheckData = (userCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetStartAutocheckData`,
        userCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('AutoCheck successfully saved.'), false, 'success', 'Success', '');
              setUsersDetailsData(response.data.data);
              UpdateUserAvtar();
              SetopenUserTabScreen(false);
              LoadUsersDetails(1);
              SetopenUserTabScreen(true);
              setIsLoading(false);
            }
            else {
              setIsLoading(false);
            }
          }
        }
      });
  };

  const LoadAllUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllUsersData([]);
    organizationUserApplyFilterData.GetAll = false;
    organizationUserApplyFilterData.CurrentPageNumber = currentPage;
    axios
      .post(`User/GetAllAccessUsers/`, organizationUserApplyFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                tag1HeaderValue.current = response.data.data[0].tag1Header;
                tag2HeaderValue.current = response.data.data[0].tag2Header;
                tag3HeaderValue.current = response.data.data[0].tag3Header;
                setAllUsersData(response.data.data);
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                UpdateAllUsers();
                SetOpenOrganizationUsersFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenOrganizationUsersFilterScreen(false);
              }
            }
            else {
              setIsLoading(false);
              SetOpenOrganizationUsersFilterScreen(false);
            }
          }
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenOrganizationUsersFilterScreen(false);
        console.log("Getting some API error when bind users details!");
      });
  };

  const ExportAllUsers = () => {
    setIsLoading(true);
    organizationUserApplyFilterData.GetAll = true;
    organizationUserApplyFilterData.CurrentPageNumber = 1;
    axios
      .post(`User/ExportAllUsersList/`,
        organizationUserApplyFilterData,
        { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "AllUsers";
            var fileType = response.headers["content-type"];
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        console.log("Getting some API error when bind users details!");
      });
  };

  const UpdateUserAvtar = () => {
    setUsersDetailsData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const UpdateAllUsers = () => {
    setAllUsersData((x) =>
      [...x].map((i) =>
        i.appStatus === "Access"
          ? { ...i, highlightText: approvedColor }
          : i.appStatus === "No Access"
            ? { ...i, highlightText: rejectcolor }
            : i.appStatus === "Not Used"
              ? { ...i, highlightText: pendingColor }
              : i
      )
    );
    UpdateAllUsersRole();
  };

  const UpdateAllUsersRole = () => {
    setAllUsersData((x) =>
      [...x].map((i) =>
        i.applicationRole === "Administrator"
          ? { ...i, highlightRoleText: organizationUserRolecolor }
          : i.applicationRole === "User"
            ? { ...i, highlightRoleText: organizationUserRolecolor }
            : i.applicationRole === "No Access"
              ? { ...i, highlightRoleText: organizationUserRolecolor }
              : i
      )
    );
    UpdateAllUserAvtar();
  };

  const UpdateAllUserAvtar = () => {
    setAllUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);

  const UpdateUserDates = () => {
    setUsersDetailsData((x) =>
      [...x].map((i) => {
        let updatedItem = { ...i };
        if (i.licenceExpiryDate != null) {
          updatedItem.licenceExpiryDateDetail = new Date(i.licenceExpiryDate);
        }
        if (i.vehicleTaxDueDate != null) {
          updatedItem.vehicleTaxDueDateDetail = new Date(i.vehicleTaxDueDate);
        }
        if (i.vehicleMotExpiryDate != null) {
          updatedItem.vehicleMotExpiryDateDetail = new Date(i.vehicleMotExpiryDate);
        }
        if (i.vehicleInsuranceExpiryDate != null) {
          updatedItem.vehicleInsuranceExpiryDateDetail = new Date(i.vehicleInsuranceExpiryDate);
        }
        return updatedItem;
      })
    );
  };

  const LoadUsersDetails = (currentPage: number) => {
    setIsLoading(true);
    setUsersDetailsData([]);
    localUserApplyFilterData.GetAll = false;
    localUserApplyFilterData.CurrentPageNumber = currentPage;
    axios
      .post(`User/GetAllLocalUsersDetailsList`, localUserApplyFilterData, Headers)
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setUsersDetailsData(response.data.data);
                UpdateUserDates();
                allUsersCheckboxDetails.current = [];
                setdisabled(true);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenDOCUsersFilterScreen(false);
                setIsLoading(false);
              }
              else {
                setIsLoading(false);
                SetOpenDOCUsersFilterScreen(false);
              }
            }
            else {
              setIsLoading(false);
              SetOpenDOCUsersFilterScreen(false);
            }
          }
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenDOCUsersFilterScreen(false);
        console.log("Getting some API error when bind chart details data!");
      });
  };

  const ExportUsersDetails = () => {
    setIsLoading(true);
    localUserApplyFilterData.GetAll = true;
    localUserApplyFilterData.CurrentPageNumber = 1;
    axios
      .post(`User/ExportAllLocalUsersDetailsList`,
        localUserApplyFilterData,
        { responseType: 'blob' })
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "AllDOCUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        SetOpenDOCUsersFilterScreen(false);
        console.log("Getting some API error when bind chart details data!");
      });
  };

  const handlePageChangeRequest = (e: any) => {
    LoadUsersDetails(e.page);
  };

  const handleuserPageChangeRequest = (e: any) => {
    LoadAllUsers(e.page);
  };

  const OnUsersDetailsFilterApplyClick = (e: any) => {
    let docCountOfFilter = 0;

    if (localUserFilterData.Name !== "" && localUserFilterData.Name !== null && localUserFilterData.Name !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.Name = localUserFilterData.Name;
    } else if (localUserFilterData.Name == "" || localUserFilterData.Name == undefined) {
      localUserApplyFilterData.Name = localUserFilterData.Name;
    }

    if (localUserFilterData.VehicleRegistrationNumber !== "" && localUserFilterData.VehicleRegistrationNumber !== null && localUserFilterData.VehicleRegistrationNumber !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.VehicleRegistrationNumber = localUserFilterData.VehicleRegistrationNumber
    } else if (localUserFilterData.VehicleRegistrationNumber == "" || localUserFilterData.VehicleRegistrationNumber == undefined) {
      localUserApplyFilterData.VehicleRegistrationNumber = localUserFilterData.VehicleRegistrationNumber
    }

    if (localUserFilterData.LicenceNo !== "" && localUserFilterData.LicenceNo !== null && localUserFilterData.LicenceNo !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.LicenceNo = localUserFilterData.LicenceNo
    } else if (localUserFilterData.LicenceNo == "" || localUserFilterData.LicenceNo == undefined) {
      localUserApplyFilterData.LicenceNo = localUserFilterData.LicenceNo
    }

    if (localUserFilterData.VehicleLicenceExpiryDate !== null && localUserFilterData.VehicleLicenceExpiryDate !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.VehicleLicenceExpiryDate = localUserFilterData.VehicleLicenceExpiryDate;
    } else if (localUserFilterData.VehicleLicenceExpiryDate == "" || localUserFilterData.VehicleLicenceExpiryDate == undefined) {
      localUserApplyFilterData.VehicleLicenceExpiryDate = localUserFilterData.VehicleLicenceExpiryDate;
    }

    if (localUserFilterData.VehicleTaxDueDate !== null && localUserFilterData.VehicleTaxDueDate !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.VehicleTaxDueDate = localUserFilterData.VehicleTaxDueDate;
    } else if (localUserFilterData.VehicleTaxDueDate == "" || localUserFilterData.VehicleTaxDueDate == undefined) {
      localUserApplyFilterData.VehicleTaxDueDate = localUserFilterData.VehicleTaxDueDate;
    }

    if (localUserFilterData.VehicleMotExpiryDate !== null && localUserFilterData.VehicleMotExpiryDate !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.VehicleMotExpiryDate = localUserFilterData.VehicleMotExpiryDate;
    } else if (localUserFilterData.VehicleMotExpiryDate == "" || localUserFilterData.VehicleMotExpiryDate == undefined) {
      localUserApplyFilterData.VehicleMotExpiryDate = localUserFilterData.VehicleMotExpiryDate;
    }

    if (localUserFilterData.VehicleInsuranceExpiryDate !== null && localUserFilterData.VehicleInsuranceExpiryDate !== undefined) {
      docCountOfFilter++;
      localUserApplyFilterData.VehicleInsuranceExpiryDate = localUserFilterData.VehicleInsuranceExpiryDate;
    } else if (localUserFilterData.VehicleInsuranceExpiryDate == "" || localUserFilterData.VehicleInsuranceExpiryDate == undefined) {
      localUserApplyFilterData.VehicleInsuranceExpiryDate = localUserFilterData.VehicleInsuranceExpiryDate;
    }

    clearLocalUserFilterData();
    setdocUsersFilterCount(String(docCountOfFilter));
    LoadUsersDetails(1);
  };

  const clearLocalUserFilterData = () => {
    localUserFilterData.ChartFilter = false;
    localUserFilterData.ChartLevelHigh = 0;
    localUserFilterData.ChartLevelLow = 0;
    localUserFilterData.ChartName = "";
    localUserFilterData.ChartValue = "";
    localUserFilterData.Department = "";
    localUserFilterData.JobTitle = "";
    localUserFilterData.Office = "";
    localUserFilterData.Status = "";
    localUserFilterData.tag1Value = "";
    localUserFilterData.tag2Value = "";
    localUserFilterData.tag3Value = "";

    localUserApplyFilterData.ChartFilter = false;
    localUserApplyFilterData.ChartLevelHigh = 0;
    localUserApplyFilterData.ChartLevelLow = 0;
    localUserApplyFilterData.ChartName = "";
    localUserApplyFilterData.ChartValue = "";
    localUserApplyFilterData.Department = "";
    localUserApplyFilterData.JobTitle = "";
    localUserApplyFilterData.Office = "";
    localUserApplyFilterData.Status = "";
    localUserApplyFilterData.tag1Value = "";
    localUserApplyFilterData.tag2Value = "";
    localUserApplyFilterData.tag3Value = "";
  }

  const clearOrganizationUserFilterData = () => {
    organizationUserFilterData.ChartFilter = false
    organizationUserFilterData.ChartLevelHigh = 0
    organizationUserFilterData.ChartLevelLow = 0
    organizationUserFilterData.ChartName = ""
    organizationUserFilterData.ChartValue = ""
    organizationUserFilterData.LicenceNo = ""
    organizationUserFilterData.VehicleInsuranceExpiryDate = null;
    organizationUserFilterData.VehicleLicenceExpiryDate = null;
    organizationUserFilterData.VehicleMotExpiryDate = null;
    organizationUserFilterData.VehicleRegistrationNumber = "";
    organizationUserFilterData.VehicleTaxDueDate = null;

    organizationUserApplyFilterData.ChartFilter = false
    organizationUserApplyFilterData.ChartLevelHigh = 0
    organizationUserApplyFilterData.ChartLevelLow = 0
    organizationUserApplyFilterData.ChartName = ""
    organizationUserApplyFilterData.ChartValue = ""
    organizationUserApplyFilterData.LicenceNo = ""
    organizationUserApplyFilterData.VehicleInsuranceExpiryDate = null;
    organizationUserApplyFilterData.VehicleLicenceExpiryDate = null;
    organizationUserApplyFilterData.VehicleMotExpiryDate = null;
    organizationUserApplyFilterData.VehicleRegistrationNumber = "";
    organizationUserApplyFilterData.VehicleTaxDueDate = null;
  }

  const resetUserDetailFilterOnClick = (e: string, isfrom: string) => {
    clearLocalUserFilterData();
    clearOrganizationUserFilterData();
    if (e.toLowerCase() === "clear") {
      if (isfrom == "fromDOCUserFilter") {
        localUserFilterData.Name = "";
        localUserFilterData.VehicleRegistrationNumber = "";
        localUserFilterData.LicenceNo = "";
        localUserFilterData.VehicleLicenceExpiryDate = null;
        localUserFilterData.VehicleTaxDueDate = null;
        localUserFilterData.VehicleMotExpiryDate = null;
        localUserFilterData.VehicleInsuranceExpiryDate = null;

        localUserApplyFilterData.Name = "";
        localUserApplyFilterData.VehicleRegistrationNumber = "";
        localUserApplyFilterData.LicenceNo = "";
        localUserApplyFilterData.VehicleLicenceExpiryDate = null;
        localUserApplyFilterData.VehicleTaxDueDate = null;
        localUserApplyFilterData.VehicleMotExpiryDate = null;
        localUserApplyFilterData.VehicleInsuranceExpiryDate = null;
        setdocUsersFilterCount('0');
        LoadUsersDetails(1);
      } else {
        organizationUserFilterData.Name = "";
        organizationUserFilterData.VehicleRegistrationNumber = "";
        organizationUserFilterData.LicenceNo = "";
        organizationUserFilterData.VehicleLicenceExpiryDate = null;
        organizationUserFilterData.VehicleTaxDueDate = null;
        organizationUserFilterData.VehicleMotExpiryDate = null;
        organizationUserFilterData.VehicleInsuranceExpiryDate = null;
        organizationUserFilterData.tag1Value = "";
        organizationUserFilterData.tag2Value = "";
        organizationUserFilterData.tag3Value = "";
        organizationUserFilterData.Status = "";

        organizationUserApplyFilterData.Name = "";
        organizationUserApplyFilterData.VehicleRegistrationNumber = "";
        organizationUserApplyFilterData.LicenceNo = "";
        organizationUserApplyFilterData.VehicleLicenceExpiryDate = null;
        organizationUserApplyFilterData.VehicleTaxDueDate = null;
        organizationUserApplyFilterData.VehicleMotExpiryDate = null;
        organizationUserApplyFilterData.VehicleInsuranceExpiryDate = null;
        organizationUserApplyFilterData.tag1Value = "";
        organizationUserApplyFilterData.tag2Value = "";
        organizationUserApplyFilterData.tag3Value = "";
        organizationUserApplyFilterData.Status = "";
        setorganizationUsersFilterCount('0');
        LoadAllUsers(1);
      }
    } else {
      if (e.toLowerCase() === "close") {
        if (isfrom == "fromDOCUserFilter") {
          SetOpenDOCUsersFilterScreen(false);
          localUserFilterData.Name = localUserApplyFilterData.Name;
          localUserFilterData.VehicleRegistrationNumber = localUserApplyFilterData.VehicleRegistrationNumber;
          localUserFilterData.LicenceNo = localUserApplyFilterData.LicenceNo;
          localUserFilterData.VehicleLicenceExpiryDate = localUserApplyFilterData.VehicleLicenceExpiryDate;
          localUserFilterData.VehicleTaxDueDate = localUserApplyFilterData.VehicleTaxDueDate;
          localUserFilterData.VehicleMotExpiryDate = localUserApplyFilterData.VehicleMotExpiryDate;
          localUserFilterData.VehicleInsuranceExpiryDate = localUserApplyFilterData.VehicleInsuranceExpiryDate;
        } else {
          SetOpenOrganizationUsersFilterScreen(false);
          organizationUserFilterData.Name = organizationUserApplyFilterData.Name;
          organizationUserFilterData.VehicleRegistrationNumber = organizationUserApplyFilterData.VehicleRegistrationNumber;
          organizationUserFilterData.LicenceNo = organizationUserApplyFilterData.LicenceNo;
          organizationUserFilterData.VehicleLicenceExpiryDate = organizationUserApplyFilterData.VehicleLicenceExpiryDate;
          organizationUserFilterData.VehicleTaxDueDate = organizationUserApplyFilterData.VehicleTaxDueDate;
          organizationUserFilterData.VehicleMotExpiryDate = organizationUserApplyFilterData.VehicleMotExpiryDate;
          organizationUserFilterData.VehicleInsuranceExpiryDate = organizationUserApplyFilterData.VehicleInsuranceExpiryDate;
          organizationUserFilterData.tag1Value = organizationUserApplyFilterData.tag1Value;
          organizationUserFilterData.tag2Value = organizationUserApplyFilterData.tag2Value;
          organizationUserFilterData.tag3Value = organizationUserApplyFilterData.tag3Value;
          organizationUserFilterData.Status = organizationUserApplyFilterData.Status;
        }
      }
    }
  }

  const OnOrganizationUsersDetailsFilterApplyClick = (e: any) => {
    let organizationCountOfFilter = 0;

    if (organizationUserFilterData.Name !== "" && organizationUserFilterData.Name !== null && organizationUserFilterData.Name !== undefined) {
      organizationCountOfFilter++;
      organizationUserApplyFilterData.Name = organizationUserFilterData.Name;
    } else if (organizationUserFilterData.Name == "" || organizationUserFilterData.Name == undefined) {
      organizationUserApplyFilterData.Name = organizationUserFilterData.Name;
    }

    if (organizationUserFilterData.tag1Value !== "" && organizationUserFilterData.tag1Value !== null && organizationUserFilterData.tag1Value !== undefined) {
      organizationCountOfFilter++;
      organizationUserApplyFilterData.tag1Value = organizationUserFilterData.tag1Value;
    } else if (organizationUserFilterData.tag1Value == "" || organizationUserFilterData.tag1Value == undefined) {
      organizationUserApplyFilterData.tag1Value = organizationUserFilterData.tag1Value;
    }

    if (organizationUserFilterData.tag2Value !== "" && organizationUserFilterData.tag2Value !== null && organizationUserFilterData.tag2Value !== undefined) {
      organizationCountOfFilter++;
      organizationUserApplyFilterData.tag2Value = organizationUserFilterData.tag2Value;
    } else if (organizationUserFilterData.tag2Value == "" || organizationUserFilterData.tag2Value == undefined) {
      organizationUserApplyFilterData.tag2Value = organizationUserFilterData.tag2Value;
    }

    if (organizationUserFilterData.tag3Value !== "" && organizationUserFilterData.tag3Value !== null && organizationUserFilterData.tag3Value !== undefined) {
      organizationCountOfFilter++;
      organizationUserApplyFilterData.tag3Value = organizationUserFilterData.tag3Value;
    } else if (organizationUserFilterData.tag3Value == "" || organizationUserFilterData.tag3Value == undefined) {
      organizationUserApplyFilterData.tag3Value = organizationUserFilterData.tag3Value;
    }

    if (organizationUserFilterData.Status !== "" && organizationUserFilterData.Status !== null && organizationUserFilterData.Status !== undefined) {
      organizationCountOfFilter++;
      organizationUserApplyFilterData.Status = organizationUserFilterData.Status;
    } else if (organizationUserFilterData.Status == "" || organizationUserFilterData.Status == undefined) {
      organizationUserApplyFilterData.Status = organizationUserFilterData.Status;
    }

    clearOrganizationUserFilterData();
    setorganizationUsersFilterCount(String(organizationCountOfFilter));
    LoadAllUsers(1);
  };

  let handleChange = (evt: any) => {
    let value;
    if (evt.target.name === "veh-MOT" || evt.target.name === "veh-Tax-Due") {
      if (evt.target.value !== undefined && evt.target.value != null) {
        value = new Date(
          Date.UTC(
            evt.target.value.getFullYear(),
            evt.target.value.getMonth(),
            evt.target.value.getDate(),
            0,
            0,
            0,
            0
          )
        );
      }
    } else {
      value = evt.target.value;
    }
    if (evt.target.name === "veh-MOT") {
      setlocalUserFilterData({
        ...localUserFilterData,
        VehicleMotExpiryDate: value,
      });
    } if (evt.target.name === "veh-Insurance-Due") {
      setlocalUserFilterData({
        ...localUserFilterData,
        VehicleInsuranceExpiryDate: value,
      });
    } if (evt.target.name === "veh-Licence-Due") {
      setlocalUserFilterData({
        ...localUserFilterData,
        VehicleLicenceExpiryDate: value,
      });
    } if (evt.target.name === "veh-Tax-Due") {
      setlocalUserFilterData({
        ...localUserFilterData,
        VehicleTaxDueDate: value,
      });
    }
  };

  let handleTextChange = (evt: any) => {
    if (evt.target.name === "usr-name") {
      setlocalUserFilterData({
        ...localUserFilterData,
        Name: evt.target.value,
      });
    } if (evt.target.name === "usr-registrationNo") {
      setlocalUserFilterData({
        ...localUserFilterData,
        VehicleRegistrationNumber: evt.target.value,
      });
    } if (evt.target.name === "usr-drivingLicence") {
      setlocalUserFilterData({
        ...localUserFilterData,
        LicenceNo: evt.target.value,
      });
    }
    if (evt.target.name === "org-usr-name") {
      setorganizationUserFilterData({
        ...organizationUserFilterData,
        Name: evt.target.value,
      });
    }
  };

  const renderCell = (row: any) => {
    return (<label style={row.row.original.appStatus === "No Access" ? noAccessUserColor : row.row.original.appStatus === "Access" ? accessUserColor : notUsedUsercolor}>{row.row.original.appStatus}</label>)
  }

  const usersDetailsCheckbox = (row: any) => {
    return (<Checkbox onChange={(event: any) => { userRowchecked(row, event.target.value); }} />);
  }

  const archivedUserViewButton = (row: any) => {
    return (
      <SquareButton
        icon={<ViewFilled />}
        style={{ height: "24px", width: "24px", padding: "2px" }}
        onClick={() => onUserRowClick(row)}
      />
    );
  }

  const allowAutoCheckColumn = (row: any) => {
    let columnValue = row.cell.getValue();
    if (columnValue) {
      return (
        <CircleTickFilled size="medium" style={{ fill: "#007A7A" }} />
      );
    } else {
      return (
        <CircleTickFilled size="medium" style={{ fill: "#CCE4E4" }} />
      );
    }
  }

  const allUsercolumns = React.useMemo(() => [
    { accessorKey: 'avatarURL320', header: 'User', meta: { dataType: 'image' } },
    { accessorKey: 'fullName', header: 'Name' },
    {
      accessorKey: 'tag1Value', header: () => <div>{tag1HeaderValue.current}</div>
    },
    {
      accessorKey: 'tag2Value', header: () => <div>{tag2HeaderValue.current}</div>
    },
    {
      accessorKey: 'tag3Value', header: () => <div>{tag3HeaderValue.current}</div>
    },
    {
      accessorKey: 'appStatus',
      header: () => <div style={{ textAlign: 'center' }}>Status</div>,
      cell: renderCell,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'Center' } };
        },
      },
    },
    { accessorKey: 'applicationRole', header: 'User Role' },
  ], [],);

  const usersDetailsDatacolumns = React.useMemo(() => [
    {
      accessorKey: 'Checkbox',
      header: () => <div style={{ textAlign: 'center' }}>Start/Stop</div>,
      cell: usersDetailsCheckbox,
      meta: { getCellProps: (cell: any) => { return { style: { textAlign: 'center', width: '50px' } }; }, },
    },
    { accessorKey: 'avatarURL320', header: 'User', meta: { dataType: 'image' } },
    { accessorKey: 'fullName', header: 'Name' },
    { accessorKey: 'vehicleRegistrationNumber', header: 'Registration No.' },
    { accessorKey: 'licenceNo', header: 'Driving Licence' },
    { accessorKey: 'licenceExpiryDateDetail', header: 'Driving Licence Due', meta: { dataType: 'date' } },
    { accessorKey: 'vehicleTaxDueDateDetail', header: 'Tax Due', meta: { dataType: 'date' } },
    { accessorKey: 'vehicleMotExpiryDateDetail', header: 'MOT Due', meta: { dataType: 'date' } },
    { accessorKey: 'vehicleInsuranceExpiryDateDetail', header: 'Insurance Due', meta: { dataType: 'date' } },
    {
      accessorKey: 'allowAutoCheck',
      header: () => <div style={{ textAlign: 'center', width: "60px" }}>AutoCheck</div>,
      cell: allowAutoCheckColumn,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'center', width: '40px' } };
        },
      },
    },
    {
      accessorKey: 'View',
      header: () => <div style={{ textAlign: 'center', width: "40px" }}>View</div>,
      cell: archivedUserViewButton,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'center', width: '50px' } };
        },
      },
    }
  ], [],);



  const onBtnConfirmationCancelClick = (e: any) => {
    setshowArchiveUserConfirmationPopup(false);
  };

  const onBtnConfirmationSaveClick = (e: any) => {
    SetStopAutocheckData(allUsersCheckboxDetails.current);
    setshowArchiveUserConfirmationPopup(false);
  };

  return (
    <>
      <div>
        {!openuserScreen ? (
          <Tabs type="underline" accent="teal" defaultValue="DOCUser" value={selectedTab} onChange={OnTabChange}>
            <TabHeaders>
              <TabHeader value="DOCUser">Duty Of Care</TabHeader>
              <TabHeader value="OrganisationUser">Organisation</TabHeader>
            </TabHeaders>
            <TabContent value="DOCUser" style={{ paddingTop: '10px' }}>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openUserTabScreen ? (
                    <div>
                      {/*filter_Btn" */}
                      <div className="fr mt_10 mb_10">
                        <div>
                          <SquareButton
                            icon={<MicrosoftExcelFilled />}
                            accent="teal"
                            onClick={ExportUsersDetails}
                            buttonStyle={{ "marginRight": "5px" }} />
                          <Button
                            icon={<SwitchesFilled />}
                            text="Filter"
                            onClick={OnDOCUsersFilterClick}
                            badge={docUsersFilterCount}
                            badgeAccent='teal'
                          />
                        </div>
                      </div>
                      <div>
                        <DataTable columns={usersDetailsDatacolumns} noDataElement={<P>Sorry, there is no data here.</P>} data={usersDetailsData} />
                      </div>
                      <Pager
                        showFirstLast
                        page={page}
                        pageCount={pageCount}
                        onPageRequest={handlePageChangeRequest}
                        style={{ justifyContent: 'flex-end', padding: '10px', borderBottom: '1px solid #e2ecef' }}
                      />
                      <div>
                        <div className="fr startstopAutoCheck">
                          <>
                            <Button
                              text="Start Auto Check"
                              size="large"
                              minWidth="100px"
                              accent="teal"
                              buttonStyle={{ right: "13px" }}
                              disabled={isdisabled}
                              onClick={OnStartAutoCheckedClick}
                            />

                            <Button
                              text="Stop Auto Check"
                              size="large"
                              minWidth="100px"
                              accent="teal"
                              disabled={isdisabled}
                              onClick={OnStopAutoCheckedClick}
                            />
                          </>
                        </div>
                      </div>
                      {/* Filter Model for DOC User */}
                      <div>
                        <Modal
                          visible={openDOCUsersFilterScreen}
                          accent="teal"
                          heading="DOC Users Filter"
                          width="medium"
                          primaryButtonProps={{ text: "Apply", accent: "teal" }}
                          cancelButtonProps={{ text: "Clear" }}
                          onClose={() => { resetUserDetailFilterOnClick("close", "fromDOCUserFilter"); }}
                          onPrimaryButtonClick={OnUsersDetailsFilterApplyClick}
                          onCancelButtonClick={() => { resetUserDetailFilterOnClick("clear", "fromDOCUserFilter"); }}
                        >
                          <FormLayout columns={2} rows={4}>
                            <FormField
                              label="User Name"
                              editor={<Input name="usr-name" value={localUserFilterData.Name} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Registration No."
                              editor={<Input name="usr-registrationNo" value={localUserFilterData.VehicleRegistrationNumber} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />

                            <FormField
                              label="Driving Licence"
                              editor={<Input name="usr-drivingLicence" value={localUserFilterData.LicenceNo} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Licence Due"
                              editor={<DatePicker clearButton name="veh-Licence-Due" value={localUserFilterData.VehicleLicenceExpiryDate} onChange={handleChange} />}
                            />

                            <FormField
                              label="Tax Due"
                              editor={<DatePicker clearButton name="veh-Tax-Due" value={localUserFilterData.VehicleTaxDueDate} onChange={handleChange} />}
                            />
                            <FormField
                              label="MOT"
                              editor={<DatePicker clearButton name="veh-MOT" value={localUserFilterData.VehicleMotExpiryDate} onChange={handleChange} />}
                            />

                            <FormField
                              label="Insurance Due"
                              editor={<DatePicker clearButton name="veh-Insurance-Due" value={localUserFilterData.VehicleInsuranceExpiryDate} onChange={handleChange} />}
                            />
                          </FormLayout>
                        </Modal>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </TabContent>
            <TabContent value="OrganisationUser" style={{ paddingTop: '10px' }}>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openAlluserTabScreen ? (
                    <div>
                      {" "}
                      {/*filter_Btn" */}
                      <div className="fr mt_10 mb_10">
                        {" "}
                        <div>
                          <SquareButton
                            icon={<MicrosoftExcelFilled />}
                            accent="teal"
                            onClick={ExportAllUsers}
                            style={{ "marginRight": "5px" }} />
                          <Button
                            icon={<SwitchesFilled />}
                            text="Filter"
                            onClick={OnOrganizationUsersFilterClick}
                            badge={organizationUsersFilterCount}
                            badgeAccent='teal'
                          />
                        </div>
                      </div>
                      <div>
                        <DataTable columns={allUsercolumns} noDataElement={<P>Sorry, there is no data here.</P>} data={allUsersData} />
                      </div>
                      <Pager
                        showFirstLast
                        page={page}
                        pageCount={pageCount}
                        onPageRequest={handleuserPageChangeRequest}
                        style={{ justifyContent: 'flex-end', padding: '10px', borderBottom: '1px solid #e2ecef' }}
                      />
                      {/* Filter Model for Organization User */}
                      <div>
                        <Modal
                          visible={openOrganizationUsersFilterScreen}
                          accent="teal"
                          heading="Organization Users Filter"
                          width="small"
                          primaryButtonProps={{
                            text: "Apply",
                            accent: "teal"
                          }}
                          cancelButtonProps={{
                            text: "Clear"
                          }}
                          onClose={() => { resetUserDetailFilterOnClick("close", "fromOrganizationUserFilter"); }}
                          onPrimaryButtonClick={OnOrganizationUsersDetailsFilterApplyClick}
                          onCancelButtonClick={() => { resetUserDetailFilterOnClick("clear", "fromOrganizationUserFilter"); }}
                        >
                          <FormLayout columns={1} rows={5}>
                            <FormField
                              label="User Name"
                              editor={<Input name="org-usr-name" value={organizationUserFilterData.Name} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label={tag1HeaderValue.current}
                              editor={<DropDownNative
                                data={tag1Values}
                                value={organizationUserFilterData.tag1Value}
                                style={{ "width": "100%" }}
                                onChange={(e) => {
                                  organizationUserFilterData.tag1Value = e.value;
                                }}
                              />}
                            />
                            <FormField
                              label={tag2HeaderValue.current}
                              editor={<DropDownNative
                                data={tag2Values}
                                value={organizationUserFilterData.tag2Value}
                                style={{ "width": "100%" }}
                                onChange={(e) => {
                                  organizationUserFilterData.tag2Value = e.value;
                                }}
                              />}
                            />
                            <FormField
                              label={tag3HeaderValue.current}
                              editor={<DropDownNative
                                data={tag3Values}
                                value={organizationUserFilterData.tag3Value}
                                style={{ "width": "100%" }}
                                onChange={(e) => {
                                  organizationUserFilterData.tag3Value = e.value;
                                }}
                              />}
                            />
                            <FormField
                              label="Status"
                              editor={<DropDownNative
                                data={[
                                  { label: "", value: "" },
                                  { label: "Access", value: "Access" },
                                  { label: "Not Used", value: "Not Used" },
                                  { label: "No Access", value: "No Access" }
                                ]}
                                value={organizationUserFilterData.Status}
                                style={{ "width": "100%" }}
                                onChange={(e) => {
                                  organizationUserFilterData.Status = e.value;
                                }}
                              />}
                            />
                          </FormLayout>
                          {/* <TagForm submitButtonHidden={true}>
                            <div className="lic-Content">
                              <table className="lic-Form-Table">
                                <tbody>
                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>User Name</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="usr-name"
                                          editor="textbox"
                                          data-type="string"
                                          value={organizationUserFilterData.Name}
                                          onValueChange={(e) => {
                                            organizationUserFilterData.Name =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag1Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <DropDownNative
                                          data={tag1Values}
                                          value={organizationUserFilterData.tag1Value}
                                          style={{ "width": "100%" }}
                                          onChange={(e) => {
                                            organizationUserFilterData.tag1Value = e.value;
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag2Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <DropDownNative
                                          data={tag2Values}
                                          value={organizationUserFilterData.tag2Value}
                                          style={{ "width": "100%" }}
                                          onChange={(e) => {
                                            organizationUserFilterData.tag2Value = e.value;
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>{tag3Header}</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <DropDownNative
                                          data={tag3Values}
                                          value={organizationUserFilterData.tag3Value}
                                          style={{ "width": "100%" }}
                                          onChange={(e) => {
                                            organizationUserFilterData.tag3Value = e.value;
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>

                                  <tr>
                                    <td className="w_22">
                                      <div>
                                        <tag-text>Status</tag-text>
                                      </div>
                                    </td>
                                    <td className="w_27">
                                      <div>
                                        <TagEditField
                                          name="un-usr-Status"
                                          editor="dropdown"
                                          data-type="string"
                                          options={[
                                            { label: "", value: "" },
                                            { label: "Access", value: "Access" },
                                            { label: "Not Used", value: "Not Used" },
                                            {
                                              label: "No Access",
                                              value: "No Access",
                                            },
                                          ]}
                                          value={organizationUserFilterData.Status}
                                          onValueChange={(e) => {
                                            organizationUserFilterData.Status =
                                              e.detail.value;
                                          }}
                                        ></TagEditField>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </TagForm> */}
                        </Modal>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </TabContent>
          </Tabs>
        ) : null}

        {openuserScreen ? (
          <div>
            {
              <UserDetails
                openDetailScreen={openDetailScreen}
                SetopenUserScreen={SetopenUserScreen}
                SetopenopenDetailScreen={SetopenopenDetailScreen}
                openuserScreen={openuserScreen}
                onParentRowClick={onUserRowClick}
                disabledSave={false}
                disabledStartAutoCheck={false}
                disabledStopAutoCheck={false}
              />
            }
          </div>
        ) : null}
      </div>
      <div>
        {showArchiveUserConfirmationPopup ? (
          <Confirm
            visible type="information" heading="Confirm"
            onPrimaryButtonClick={onBtnConfirmationSaveClick}
            onCancelButtonClick={onBtnConfirmationCancelClick}
            text={showStopAutoCheckConfirmationDialogue ? "Are you sure you want to stop auto check for this user?" : "Are you sure you want to stop auto check for these user?"}
            onClose={() => setshowArchiveUserConfirmationPopup(false)}
          />
        ) : null}
      </div>
    </>
  );
}
export default User;