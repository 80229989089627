import event from "./eventBus";
import { ToastMessagePosition } from "../../assets/shared/types"

export const NotifyPublisher = {
  message: (content: any, dialogueType: any, modelType: any, messageHeader: any, notifyModelOpenFrom = '', durationVal: number = 5000, toastPosition: ToastMessagePosition = 'bottom-right') => {
    event.emit("showModal", content, dialogueType, modelType, messageHeader, durationVal, toastPosition, notifyModelOpenFrom);
  },
  clearAllMessage: () => {
    event.emit("clearAllMessage");
  }
};