import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { PidgetSettings } from "../PidgetSettings";
import { Tooltip, Span } from "@tag/tag-components-react-v4";
import "../../src/assets/css/userDetail.scss";

function VehiclesDriveorNot({ data }: any) {
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const categoryName = data.doC_DVLA_L_C_Code + '.png'
  var src = url + 'Content/category/' + categoryName;
  var startDate;
  var startDateData;
  var endDate = new Date(data.doC_DVLA_L_C_To);
  var endDateData = endDate.getDate() + "/" + (endDate.getMonth() + 1) + "/" + endDate.getFullYear();

  if (data.doC_DVLA_L_C_From != null) {
    startDate = new Date(data.doC_DVLA_L_C_From);
    startDateData = startDate.getDate() + "/" + (startDate.getMonth() + 1) + "/" + startDate.getFullYear();
  } else {
    startDateData = " -"
  }

  return (
    <div>
      {data.doC_DVLA_L_C_Type === 'Full' ?
        <div className="vehicledrivecard">
          <Tooltip tooltipFor={
            <div className="vehicles-header b_1_s_border" style={{ height: "40px" }}>
              <div className="fl pl_5 pt_5 pr_5 pb_5">
                <img src={src} alt={data.doC_DVLA_L_C_Code} height='28' style={{ opacity: 0.6 }} />
              </div>
              <div className="vehicle-header-details pt_5 pr_5">
                <Span style={{ fontSize: "12px", fontWeight: 600, padding: "10px 0" }}>{data.doC_DVLA_L_C_Code}</Span>
              </div>
            </div>
          }>
            <div style={{ width: "150px" }}>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>Type: </b></Span>
                <Span style={{ float: "left" }}>{data.doC_DVLA_L_C_Type}</Span>
              </div>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>Start Date: </b></Span>
                <Span style={{ float: "left" }}>{startDateData}</Span>
              </div>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>End Date: </b></Span>
                <Span style={{ float: "left" }}>{endDateData}</Span>
              </div>
            </div>
          </Tooltip>
        </div>
        :
        <div className="vehicledrivecard">
          <Tooltip tooltipFor={
            <div className="vehicles-categoryheader b_1_s_border" style={{ height: "40px" }}>
              <div className="fl pl_5 pt_5 pr_5 pb_5">
                <img src={src} alt={data.doC_DVLA_L_C_Code} height='28' style={{ opacity: 0.6 }} />
              </div>
              <div className="vehicle-header-details pt_5 pr_5">
                <Span style={{ fontSize: "12px", fontWeight: 600, padding: "10px 0", color: "white" }}>{data.doC_DVLA_L_C_Code}</Span>
              </div>
            </div>
          }>
            <div style={{ width: "150px" }}>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>Type: </b></Span>
                <Span style={{ float: "left" }}>{data.doC_DVLA_L_C_Type}</Span>
              </div>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>Start Date: </b></Span>
                <Span style={{ float: "left" }}>{startDateData}</Span>
              </div>
              <div>
                <Span style={{ float: "left", width: "75px", whiteSpace: "nowrap", fontWeight: "bold", }}><b>End Date: </b></Span>
                <Span style={{ float: "left" }}>{endDateData}</Span>
              </div>
            </div>
          </Tooltip>
        </div>
      }
    </div>
  );
}
export default VehiclesDriveorNot;