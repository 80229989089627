/* eslint-disable no-useless-concat */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable for-direction */
/* eslint-disable no-var */
/* eslint-disable @typescript-eslint/no-empty-function */

import React from "react";
import axios from "axios";
import { HttpHeaders } from "../shared/HttpHeaders";
import { DataTable, Pager, Spinner } from "@tag/tag-components-react-v4";

interface IChartDetailsData {
  type: string;
  primary: number;
  name: string;
  date: Date;
  expiryin: number;
}

function ChartTable({ apiName }: any) {
  const Headers = HttpHeaders();
  const [chartDetailsData, setchartDetailsData] = React.useState<IChartDetailsData[]>([]);
  const pageSize = 5;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    bindChartDetailsData(page);
  }, []);

  const bindChartDetailsData = (currentPage: number) => {
    setIsLoading(true);
    setchartDetailsData([]);
    axios
      .get(
        `Admin/` + apiName + "?Pagesize=" + pageSize + "&Page=" + currentPage,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setchartDetailsData(response.data.data);
                UpdateUserDates();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                setIsLoading(false);
              }
            }
          }
          else {
            setIsLoading(false);
          }
        }
        else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind chart details data!");
      });
  };

  const handlePageChangeRequest = (e: any) => {
    bindChartDetailsData(e.page);
  };

  const UpdateUserDates = () => {
    setchartDetailsData((x) =>
      [...x].map((i) => {
        let updatedItem = { ...i };
        if (i.date != null) {
          updatedItem.date = new Date(i.date);
        }
        return updatedItem;
      })
    );
  };

  const columns = React.useMemo(() => [
    { accessorKey: 'name', header: 'Name' },
    { accessorKey: 'date', header: 'Issue Date', meta: { dataType: 'date' } },
    { accessorKey: 'expiryin', header: 'Expiry In (Days)' }
  ], [],);

  return (
    <div>
      <DataTable
        isLoading={isLoading}
        loadingElement={
          <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
            <Spinner>Waiting for data...</Spinner>
          </div>
        }
        columns={columns} data={chartDetailsData} />
      <Pager
        page={page}
        pageCount={pageCount}
        showFirstLast
        onPageRequest={handlePageChangeRequest}
        style={{ justifyContent: 'center', padding: '10px', borderBottom: '1px solid #e2ecef' }}
      />
    </div>
  );
}
export default ChartTable;
