import React from "react";
import { useState } from "react";
import { ILicenceData } from "../../core/view-Model/userdetails.model";
import axios from "axios";
import { HttpHeaders } from "../../shared/HttpHeaders";
import LoadingSpinner from "./../../components/LoadingSpinner";
import { Button, Confirm, FormField, FormLayout, Input, Modal, Textarea } from "@tag/tag-components-react-v4";

function ManuallyAddLicence({
  openLicenceScreen,
  licencevehicleData,
  licenceDetailsData,
  SetopenLicenceScreen,
  datecometoUK,
  licenceNo,
  issuedate,
  expirydate,
  fileName,
  onParentRowClick,
  issuedateDetail,
  expirydateDetail
}: any) {
  const [showPopup, setshowPopup] = useState(false);
  const [showRejectReasonModel, setshowRejectReasonModel] = useState(false);
  const [showLicenceImageModel, setshowLicenceImageModel] = useState(false);
  const [rejectLicenceReason, setrejectLicencReason] = useState("");
  const Headers = HttpHeaders();
  const [showRejectionSaveVehicleButton, setshowRejectionSaveVehicleButton] = useState(false);
  const [isApprove, setisApprove] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const [licenceData, SetLicenceData] = React.useState<ILicenceData>({
    address: "",
    categories: "",
    doC_USER_ID: "",
    dob: new Date(),
    expirydate: "",
    issuedate: "",
    lasT_UPDATED_ON: new Date(),
    licenceno: "",
    name: "",
    primary: 0,
    surname: "",
    vehiclE_YOUPROVISONALLY_DRIVE: "",
    assessinG_FITNESS_DRIVE: false,
    roadworthy: false,
    drivingwitH_MEDICAL_CONDITION: false,
    drivingeyE_SIGHT: false,
    bluebadge: false,
    qualifieD_FOR_LORRY_BUS_COACH: false,
    doC_DVLA_L_O_EXPIRYDATE: "",
    doC_DVLA_L_O_NAME: "",
    doC_DVLA_L_O_POINTS: 0,
    doC_DVLA_L_O_STARTDATE: "",
    doC_DVLA_L_O_LEGALLITERAL: "",
    mode: "",
    rejectReason: "",
    licenceStatus: "",
    licenceType: "",
    performing_userId: ""
  });

  React.useEffect(() => {
    if (licenceDetailsData.isapprovedvalue === "Approved" || licenceDetailsData.isapprovedvalue === "Reject") {
      setisApprove(true);
    } else {
      setisApprove(false);
    }
  }, [licenceDetailsData.isapprovedvalue]);

  const onCancelButtonClick = (e: any) => {
    SetopenLicenceScreen(false);
    setshowPopup(false);
  };

  const onApproveButtonClick = (e: any) => {
    setshowPopup(true);
  };

  const onRejectButtonClick = (e: any) => {
    setshowPopup(false);
    setshowRejectReasonModel(true);
    setrejectLicencReason("");
    setshowRejectionSaveVehicleButton(false);
  };

  const onConfirmSaveButtonClick = (e: any) => {
    setshowPopup(false);
    SetApproveRejectLicenceData("Approved");
    SetopenLicenceScreen(false);
  };

  const onConfirmCancelButtonClick = (e: any) => {
    setshowPopup(false);
    SetopenLicenceScreen(false);
    setshowRejectReasonModel(false);
  };

  var expiryDateData = new Date(issuedateDetail);
  var issueDateData = new Date(expirydateDetail);

  function SetApproveRejectLicenceData(mode: any) {
    licenceData.address = "";
    licenceData.categories = "";
    licenceData.doC_USER_ID = licenceDetailsData.doC_USER_ID;
    licenceData.dob = new Date();
    licenceData.expirydate = expiryDateData.getFullYear() + "-" + (expiryDateData.getMonth() + 1) + '-' + expiryDateData.getDate();
    licenceData.issuedate = issueDateData.getFullYear() + "-" + (issueDateData.getMonth() + 1) + '-' + issueDateData.getDate();
    licenceData.lasT_UPDATED_ON = new Date();
    licenceData.licenceno = licenceNo;
    licenceData.name = "";
    licenceData.primary = 0;
    licenceData.surname = "";
    licenceData.vehiclE_YOUPROVISONALLY_DRIVE = "";
    licenceData.assessinG_FITNESS_DRIVE = false;
    licenceData.roadworthy = false;
    licenceData.drivingwitH_MEDICAL_CONDITION = false;
    licenceData.drivingeyE_SIGHT = false;
    licenceData.bluebadge = false;
    licenceData.qualifieD_FOR_LORRY_BUS_COACH = false;
    licenceData.doC_DVLA_L_O_EXPIRYDATE = "";
    licenceData.doC_DVLA_L_O_NAME = "";
    licenceData.doC_DVLA_L_O_POINTS = 0;
    licenceData.doC_DVLA_L_O_STARTDATE = "";

    if (mode === "Approved") {
      licenceData.mode = "Approved";
    } else {
      licenceData.mode = "Rejected";
      licenceData.rejectReason = rejectLicenceReason;
    }
    approveRejectLicence();
  }

  function approveRejectLicence(): void {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("request", JSON.stringify(licenceData));
    Headers.headers["Content-Type"] = "multipart/form-data";
    axios
      .post(
        `licence/ApprovedRejectManuallyAddLicence`,
        formData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              setshowPopup(false);
              SetopenLicenceScreen(false);
              onParentRowClick();
              setIsLoading(false);
            }
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
      });
  }

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    fileName = licencevehicleData.doC_D_FILE_NAME;
    fileType = licencevehicleData.doC_D_FILE_CONTENT_TYPE;
    fileData = licencevehicleData.doC_D_FILE_DATA;

    var fileBinaryString = atob(fileData);
    var dowloadFileBytes = new Uint8Array(fileBinaryString.length);
    for (var i = 0; i < fileBinaryString.length; i++) {
      var ascii = fileBinaryString.charCodeAt(i);
      dowloadFileBytes[i] = ascii;
    }

    var file = new Blob([dowloadFileBytes], {
      type: fileType,
    });

    if (allowedFileTypes.includes(fileType)) {
      var motFileObjectWithFileTypeURL = URL.createObjectURL(file);
      setImageSrc(motFileObjectWithFileTypeURL);
      setshowLicenceImageModel(true);
    } else {
      var motFileObjectURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = motFileObjectURL;
      link.download = fileName;
      link.click();
    }
  }

  const onLicenceRejectionValueChange = (e: any) => {
    setrejectLicencReason(e.value);
    if (e.value !== "") {
      setshowRejectionSaveVehicleButton(true);
    } else {
      setshowRejectionSaveVehicleButton(false);
    }
  };

  const onRejectReasonSaveButtonClick = (e: any) => {
    if (rejectLicenceReason === "") {
      setshowRejectionSaveVehicleButton(false);
    } else {
      setshowRejectionSaveVehicleButton(true);
      SetApproveRejectLicenceData("Reject");
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Modal
          visible={openLicenceScreen}
          accent="teal"
          heading="Licence Details"
          primaryButtonProps={{ buttonStyle: { display: "none" } }}
          onCancelButtonClick={onCancelButtonClick}
          onClose={() => {
            SetopenLicenceScreen(false);
            setshowPopup(false);
          }}
          additionalButtons={
            <>
              {isApprove ? null : (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '8px' }}>
                  <Button size="large" onClick={onApproveButtonClick} accent="teal">Verify</Button>
                  <Button size="large" onClick={onRejectButtonClick}>Reject</Button>
                </div>
              )}
            </>
          }
        >
          <div className="lic-Content">
            <FormLayout columns={1} rows={6}>
              <FormField
                label="Country"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="lic-Vehicle-Number" value={licenceDetailsData.countrY_NAME} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Date when you come to UK"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="lic-Insurance-Term" value={datecometoUK} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Licence No."
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="lic-tax-due-date" value={licenceNo} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Start Date"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="lic-Registration-Month" value={issuedate} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Expiry Date"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="lic-ins-status" value={expirydate} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Licence Document"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="licenceDocument">{fileName}</a>}
                disabled
              />
            </FormLayout>
          </div>
        </Modal>
      )}
      <div>
        {showPopup ? (
          <Confirm
            visible
            width="small"
            type="question"
            heading="Confirm"
            primaryButtonProps={{ text: "Yes" }}
            cancelButtonProps={{ text: "No" }}
            onPrimaryButtonClick={onConfirmSaveButtonClick}
            onCancelButtonClick={onConfirmCancelButtonClick}
            onClose={() => {
              setshowPopup(false);
              SetopenLicenceScreen(false);
              setshowRejectReasonModel(false);
            }}
            text="Are you sure you want to approve Licence?"
          />
        ) : null}

        {showRejectReasonModel ? (
          <>
            <div>
              {showRejectionSaveVehicleButton ? (
                <Modal
                  visible
                  accent="teal"
                  heading="Are you sure you want to reject Licence?"
                  width="small"
                  primaryButtonProps={{ text: "Yes" }}
                  onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                  onCancelButtonClick={onConfirmCancelButtonClick}
                  onClose={() => {
                    setshowPopup(false);
                    SetopenLicenceScreen(false);
                    setshowRejectReasonModel(false);
                  }}
                >
                  <Textarea id="textAreaId" name="exampleName" resize="none" rows={1} placeholder="Please enter your reason for rejection"
                    value={rejectLicenceReason} onChange={onLicenceRejectionValueChange}
                    style={{ width: "500px", backgroundColor: "white", minHeight: "110px" }} />
                </Modal>
              ) : (
                <Modal
                  visible
                  accent="teal"
                  heading="Are you sure you want to reject Licence?"
                  width="small"
                  primaryButtonProps={{ buttonStyle: { display: "none" } }}
                  onCancelButtonClick={onConfirmCancelButtonClick}
                  onClose={() => {
                    setshowPopup(false);
                    SetopenLicenceScreen(false);
                    setshowRejectReasonModel(false);
                  }}
                >
                  <Textarea id="textAreaId" name="exampleName" resize="none" rows={1} placeholder="Please enter your reason for rejection"
                    value={rejectLicenceReason} onChange={onLicenceRejectionValueChange}
                    style={{ width: "500px", backgroundColor: "white", minHeight: "110px", color: "black", border: "none" }} />
                </Modal>
              )}
            </div>
          </>
        ) : null}

        {showLicenceImageModel ? (
          <div>
            <Modal
              visible
              accent="teal"
              heading="Licence Document"
              cancelButtonProps={{ buttonStyle: { display: "none" } }}
              primaryButtonProps={{ buttonStyle: { display: "none" } }}
              onClose={() => { setshowLicenceImageModel(false); }}
              scroll="content"
            >
              <div style={{ textAlign: "center" }}>
                <img id="licenceDocumentFileImage" src={imageSrc} alt="Licence Document" />
              </div>
            </Modal>
          </div>
        ) : null
        }
      </div>
    </div>
  );
}
export default ManuallyAddLicence;