/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */

import axios from "axios";
import React from "react";
import { useState } from "react";
import { IvehicleData } from "../../core/view-Model/userdetails.model";
import { HttpHeaders } from "../../shared/HttpHeaders";
import LoadingSpinner from "./../../components/LoadingSpinner";
import { Button, Confirm, FormField, FormLayout, H5, Input, Modal, Textarea } from "@tag/tag-components-react-v4";
import "../../assets/css/common.scss";

function ManuallyAddVehicle({
  vehicleDetailsData,
  SetopenVehicleDetailScreen,
  makeforExport,
  taxDueDate,
  motExpiryDate,
  lastV5cIssuedDate,
  insStartDate,
  insTerm,
  insExpiryDate,
  SetopenaApprovalScreen,
  SetshowManuallyAddVehicle,
  showManuallyAddVehicle,
  onParentRowClick
}: any) {
  const [showPopup, setshowPopup] = useState(false);
  const [showRejectReasonModel, setshowRejectReasonModel] = useState(false);
  const [rejectVehicleReason, setrejectVehicleReason] = useState("");
  const [showRejectionSaveVehicleButton, setshowRejectionSaveVehicleButton] = useState(false);
  const [isapprove, setisapprove] = useState(false);
  const Headers = HttpHeaders();
  const [taxDocumentValue, SettaxDocumentValue] = useState("");
  const [motDocumentValue, SetmotDocumentValue] = useState("");
  const [insuranceDocumentValue, SetinsuranceDocumentValue] = useState("");
  const [showInsuranceImageModel, setshowInsuranceImageModel] = useState(false);
  const [showMOTImageModel, setshowMOTImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const [insuranceImageSrc, setinsuranceImageSrc] = useState('');
  const [motImageSrc, setmotImageSrc] = useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  const [vehicleData, SetvehicleData] = React.useState<IvehicleData>({
    manuaalyaddvehiclereason: "",
    doC_USER_ID: "",
    registertioN_NUMBER: "",
    cO2EMISSIONS: 0,
    enginE_CAPACITY: 0,
    markedforexport: false,
    fueL_TYPE: "",
    moT_STATUS: "",
    colour: "",
    make: "",
    typE_APPROVAL: "",
    yeaR_OF_MANUFACTURE: 0,
    taX_DUE_DATE: new Date(),
    taX_STATUS: "",
    datE_OF_LAST_V5C_ISSUED: new Date(),
    moT_EXPIRY_DATE: new Date(),
    wheeL_PLAN: "",
    montH_OF_REGISTRATION: "",
    revenuE_WEIGHT: 0,
    eurostatus: "",
    lasT_UPDATED_ON: new Date(),
    iscompanY_VEHICLE: false,
    vehicleFrequencyFrom: "",
    vehicleFrequencyType: "",
    isapproved: 1,
    insurancE_EXPIRY_DATE: new Date(),
    insurancE_START_DATE: new Date(),
    insurancE_STATUS: new Date(),
    ismanuallyaddvehicle: true,
    mode: "",
    rejecT_REASON: "",
    insurancedocument: "",
    motdocument: "",
    taxdocument: "",
    countrY_NAME: "",
    insurancE_POLICYNUMBER: "",
    vehicleImage: "",
    performing_userId: "",
  });

  React.useEffect(() => {
    if (
      vehicleDetailsData.isapprovedvalue === "Approved" ||
      vehicleDetailsData.isapprovedvalue === "Reject"
    ) {
      setisapprove(true);
    } else {
      setisapprove(false);
    }

    if (vehicleDetailsData !== null && vehicleDetailsData !== undefined) {
      if (vehicleDetailsData.taxdocument === null) {
        SettaxDocumentValue("");
      } else {
        SettaxDocumentValue(vehicleDetailsData.taxdocument.doC_D_FILE_NAME);
      }
      if (vehicleDetailsData.motdocument === null) {
        SetmotDocumentValue("");
      } else {
        SetmotDocumentValue(vehicleDetailsData.motdocument.doC_D_FILE_NAME);
      }
      if (vehicleDetailsData.insurancedocument === null) {
        SetinsuranceDocumentValue("");
      } else {
        SetinsuranceDocumentValue(
          vehicleDetailsData.insurancedocument.doC_D_FILE_NAME
        );
      }
    }
  }, [vehicleDetailsData]);

  const onCancelButtonClick = (e: any) => {
    SetshowManuallyAddVehicle(false);
    setshowPopup(false);
  };

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    if (e.target.id === "insuranceDocument" && vehicleDetailsData.insurancedocument !== null) {
      fileName = vehicleDetailsData.insurancedocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.insurancedocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.insurancedocument.doC_D_FILE_DATA;

      var insuranceFileBinaryString = atob(fileData);
      var insuranceDowloadFileBytes = new Uint8Array(insuranceFileBinaryString.length);
      for (var i = 0; i < insuranceFileBinaryString.length; i++) {
        var insuranceAscii = insuranceFileBinaryString.charCodeAt(i);
        insuranceDowloadFileBytes[i] = insuranceAscii;
      }

      var file = new Blob([insuranceDowloadFileBytes], {
        type: fileType,
      });

      var insuranceFileObjectURL = URL.createObjectURL(file);

      if (allowedFileTypes.includes(fileType)) {
        setinsuranceImageSrc(insuranceFileObjectURL);
        setshowInsuranceImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = insuranceFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }

    if (e.target.id === "motDocument" && vehicleDetailsData.motdocument !== null) {
      fileName = vehicleDetailsData.motdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.motdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.motdocument.doC_D_FILE_DATA;

      var motFileBinaryString = atob(fileData);
      var motDowloadFileBytes = new Uint8Array(motFileBinaryString.length);
      for (var j = 0; j < motFileBinaryString.length; j++) {
        var motAscii = motFileBinaryString.charCodeAt(j);
        motDowloadFileBytes[j] = motAscii;
      }

      var motFile = new Blob([motDowloadFileBytes], {
        type: fileType,
      });

      var motFileObjectURL = URL.createObjectURL(motFile);

      if (allowedFileTypes.includes(fileType)) {
        setmotImageSrc(motFileObjectURL);
        setshowMOTImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = motFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }
  };

  const onApproveButtonClick = (e: any) => {
    setshowPopup(true);
  };

  const onRejectButtonClick = (e: any) => {
    setshowPopup(false);
    setshowRejectReasonModel(true);
    setrejectVehicleReason("");
    setshowRejectionSaveVehicleButton(false);
  };

  const onConfirmSaveButtonClick = (e: any) => {
    setshowPopup(false);
    SetRejectvehicleData("Approved");
  };

  const onConfirmCancelButtonClick = (e: any) => {
    setshowPopup(false);
    SetshowManuallyAddVehicle(false);
  };

  const onVehicleRejectionValueChange = (e: any) => {
    setrejectVehicleReason(e.value);
    if (e.value !== "") {
      setshowRejectionSaveVehicleButton(true);
    } else {
      setshowRejectionSaveVehicleButton(false);
    }
  };

  const onRejectReasonSaveButtonClick = (e: any) => {
    console.log(rejectVehicleReason);
    if (rejectVehicleReason === "") {
      setshowRejectionSaveVehicleButton(false);
    } else {
      setshowRejectionSaveVehicleButton(true);
      SetRejectvehicleData("Reject");
    }
  };

  function SetRejectvehicleData(mode: any) {
    if (vehicleDetailsData != null && vehicleDetailsData !== undefined) {
      vehicleData.manuaalyaddvehiclereason = vehicleDetailsData.manuaalyaddvehiclereason;
      vehicleData.doC_USER_ID = vehicleDetailsData.doC_USER_ID;
      vehicleData.registertioN_NUMBER = vehicleDetailsData.registertioN_NUMBER;
      vehicleData.cO2EMISSIONS = vehicleDetailsData.cO2EMISSIONS;
      vehicleData.enginE_CAPACITY = vehicleDetailsData.enginE_CAPACITY;
      vehicleData.markedforexport = vehicleDetailsData.markedforexport;
      vehicleData.fueL_TYPE = vehicleDetailsData.fueL_TYPE;
      vehicleData.moT_STATUS = vehicleDetailsData.moT_STATUS;
      vehicleData.colour = vehicleDetailsData.colour;
      vehicleData.make = vehicleDetailsData.make;
      vehicleData.typE_APPROVAL = vehicleDetailsData.typE_APPROVAL;
      vehicleData.yeaR_OF_MANUFACTURE = vehicleDetailsData.yeaR_OF_MANUFACTURE;
      vehicleData.taX_DUE_DATE = vehicleDetailsData.taX_DUE_DATE;
      vehicleData.taX_STATUS = vehicleDetailsData.taX_STATUS;
      vehicleData.datE_OF_LAST_V5C_ISSUED = vehicleDetailsData.datE_OF_LAST_V5C_ISSUED;
      vehicleData.moT_EXPIRY_DATE = vehicleDetailsData.moT_EXPIRY_DATE;
      vehicleData.wheeL_PLAN = vehicleDetailsData.wheeL_PLAN;
      vehicleData.montH_OF_REGISTRATION = vehicleDetailsData.montH_OF_REGISTRATION;
      vehicleData.revenuE_WEIGHT = vehicleDetailsData.revenuE_WEIGHT;
      vehicleData.eurostatus = vehicleDetailsData.eurostatus;
      vehicleData.lasT_UPDATED_ON = vehicleDetailsData.lasT_UPDATED_ON;
      vehicleData.iscompanY_VEHICLE = vehicleDetailsData.iscompanY_VEHICLE;
      vehicleData.vehicleFrequencyFrom = vehicleDetailsData.vehicleFrequencyFrom;
      vehicleData.vehicleFrequencyType = vehicleDetailsData.vehicleFrequencyType;
      vehicleData.isapproved = vehicleDetailsData.isapproved;
      vehicleData.insurancE_EXPIRY_DATE = vehicleDetailsData.insurancE_EXPIRY_DATE;
      vehicleData.insurancE_START_DATE = vehicleDetailsData.insurancE_START_DATE;
      vehicleData.insurancE_STATUS = vehicleDetailsData.insurancE_STATUS;
      vehicleData.ismanuallyaddvehicle = vehicleDetailsData.ismanuallyaddvehicle;
      vehicleData.rejecT_REASON = vehicleDetailsData.rejecT_REASON;
      vehicleData.insurancedocument = vehicleDetailsData.insurancedocument;
      vehicleData.motdocument = vehicleDetailsData.motdocument;
      vehicleData.taxdocument = vehicleDetailsData.taxdocument;
      vehicleData.insurancE_POLICYNUMBER = vehicleDetailsData.insurancE_POLICYNUMBER;

      if (mode === "Approved") {
        vehicleData.mode = "Approved";
      } else {
        vehicleData.mode = "Rejected";
        vehicleData.rejecT_REASON = rejectVehicleReason;
      }
    }
    approveRejectVehicle();
  }

  const approveRejectVehicle = async () => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("request", JSON.stringify(vehicleData));
    try {
      Headers.headers["Content-Type"] = "multipart/form-data";
      const response = axios.post(`vehicle/ApprovedRejectManuallyAddVehicle`, formData, Headers)
        .then((response) => {
          if (response !== null && response !== undefined) {
            if (response.data !== null && response.data !== undefined) {
              if (
                response.data.data !== null &&
                response.data.data !== undefined
              ) {
                setshowPopup(false);
                SetshowManuallyAddVehicle(false);
                onParentRowClick();
                setIsLoading(false);
              }
              setIsLoading(false);
            }
            setIsLoading(false);
          }
        })
        .catch((ex) => {
          console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
        });
    } catch (error) {
      console.log("Getting some API error when call ApprovedRejectManuallyAddVehicle API!");
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Modal
            visible={showManuallyAddVehicle}
            accent="teal"
            heading="Vehicle Details"
            primaryButtonProps={{ buttonStyle: { display: "none" } }}
            onCancelButtonClick={onCancelButtonClick}
            onClose={() => {
              SetshowManuallyAddVehicle(false);
              setshowPopup(false);
            }}
            additionalButtons={
              <>
                {isapprove ? null : (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '8px' }}>
                    <Button size="large" onClick={onApproveButtonClick} accent="teal">Verify</Button>
                    <Button size="large" onClick={onRejectButtonClick}>Reject</Button>
                  </div>
                )}
              </>
            }
          >
            <div className="veh-Content">
              <FormLayout columns={3} rows={6}>
                <div style={{ gridColumnEnd: 'span 1' }}>
                  <H5 style={{ fontWeight: "700", textAlign: "center" }}>Vehicle</H5>
                </div>
                <div style={{ gridColumnEnd: 'span 1' }}>
                  <H5 style={{ fontWeight: "700", textAlign: "center" }}>Insurance</H5>
                </div>
                <div style={{ gridColumnEnd: 'span 1' }}>
                  <H5 style={{ fontWeight: "700", textAlign: "center" }}>Tax & MOT</H5>
                </div>

                <FormField
                  label="Registration Number"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Vehicle-Number" value={vehicleDetailsData.registertioN_NUMBER} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Insurance Term"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Insurance-Term" value={insTerm} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Tax Due Date"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-tax-due-date" value={taxDueDate} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Registered"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Registration-Month" value={vehicleDetailsData.montH_OF_REGISTRATION} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Insurance End Date"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-ins-status" value={insExpiryDate} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Tax Status"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Status" value={vehicleDetailsData.taX_STATUS} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Fuel Type"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Fuel-Type" value={vehicleDetailsData.fueL_TYPE} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Insurance Status"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-ins-expiry" value={vehicleDetailsData.insurancE_STATUS} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="MOT Expiry Date"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-mot-expiry-date" value={motExpiryDate} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="CO2 Emissions"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-emisson" value={vehicleDetailsData.cO2EMISSIONS} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Insurance Policy No."
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-ins-policy-no" value={vehicleDetailsData.insurancE_POLICYNUMBER} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="MOT Status"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-mot-status" value={vehicleDetailsData.moT_STATUS} style={{ width: '100%' }} />}
                  disabled
                />

                <FormField
                  label="Engine Capacity"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<Input name="veh-Engine-Capacity" value={vehicleDetailsData.enginE_CAPACITY} style={{ width: '100%' }} />}
                  disabled
                />
                <FormField
                  label="Insurance Document"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="insuranceDocument">{insuranceDocumentValue}</a>}
                  disabled
                />
                <FormField
                  label="MOT Document"
                  labelProps={{ style: { fontWeight: '500' } }}
                  editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="motDocument">{motDocumentValue}</a>}
                  disabled
                />
              </FormLayout>
            </div>
          </Modal>
        </>
      )}
      <div>
        {showPopup ? (
          <Confirm
            visible
            width="small"
            type="question"
            heading="Confirm"
            primaryButtonProps={{ text: "Yes" }}
            cancelButtonProps={{ text: "No" }}
            onPrimaryButtonClick={onConfirmSaveButtonClick}
            onCancelButtonClick={onConfirmCancelButtonClick}
            onClose={() => {
              setshowPopup(false);
              SetshowManuallyAddVehicle(false);
            }}
            text="Are you sure you want to approve vehicle?"
          />
        ) : null}

        {showInsuranceImageModel ? (
          <>
            <div>
              <Modal
                visible
                accent="teal"
                heading="Insurance Document"
                cancelButtonProps={{ buttonStyle: { display: "none" } }}
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onClose={() => { setshowInsuranceImageModel(false); }}
                scroll="content"
              >
                <div style={{ textAlign: "center" }}>
                  <img id="insuranceDocumentFileImage" src={insuranceImageSrc} alt="Insurance Document" />
                </div>
              </Modal>
            </div>
          </>
        ) : null}

        {showMOTImageModel ? (
          <>
            <div>
              <Modal
                visible
                accent="teal"
                heading="MOT Document"
                cancelButtonProps={{ buttonStyle: { display: "none" } }}
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onClose={() => { setshowMOTImageModel(false); }}
                scroll="content"
              >
                <div style={{ textAlign: "center" }}>
                  <img id="motDocumentFileImage" src={motImageSrc} alt="MOT Document" />
                </div>
              </Modal>
            </div>
          </>
        ) : null}
      </div>

      {showRejectReasonModel ? (
        <>
          <div>
            {showRejectionSaveVehicleButton ? (
              <Modal
                visible
                accent="teal"
                heading="Are you sure you want to reject vehicle?"
                width="small"
                primaryButtonProps={{ text: "Yes" }}
                onPrimaryButtonClick={onRejectReasonSaveButtonClick}
                onCancelButtonClick={onConfirmCancelButtonClick}
                onClose={() => {
                  setshowPopup(false);
                  SetshowManuallyAddVehicle(false);
                }}
              >
                <Textarea id="textAreaId" name="exampleName" resize="none" rows={1} placeholder="Please enter your reason for rejection"
                  value={rejectVehicleReason} onChange={onVehicleRejectionValueChange}
                  style={{ width: "500px", backgroundColor: "white", minHeight: "110px" }} />
              </Modal>
            ) : (
              <Modal
                visible
                accent="teal"
                heading="Are you sure you want to reject vehicle?"
                width="small"
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onCancelButtonClick={onConfirmCancelButtonClick}
                onClose={() => {
                  setshowPopup(false);
                  SetshowManuallyAddVehicle(false);
                }}
              >
                <Textarea id="textAreaId" name="exampleName" resize="none" rows={1} placeholder="Please enter your reason for rejection"
                  value={rejectVehicleReason} onChange={onVehicleRejectionValueChange}
                  style={{ width: "500px", backgroundColor: "white", minHeight: "110px", color: "black", border: "none" }} />
              </Modal>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
}
export default ManuallyAddVehicle;
