/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import LoadingSpinner from "./../../../components/LoadingSpinner";
import { FormField, FormLayout, H5, Input, Modal } from "@tag/tag-components-react-v4";
import "../../../assets/css/userDetail.scss";

function VehicleDetails({
  vehicleDetailsData,
  openVehicleDetailScreen,
  SetopenVehicleDetailScreen,
  isLoading,
  makeforExport,
  taxDueDate,
  motExpiryDate,
  lastV5cIssuedDate,
  insStartDate,
  insTerm,
  insExpiryDate
}: any) {
  const onCancelButtonClick = (e: any) => {
    SetopenVehicleDetailScreen(false);
  };

  const [taxDocumentValue, SettaxDocumentValue] = useState("");
  const [motDocumentValue, SetmotDocumentValue] = useState("");
  const [insuranceDocumentValue, SetinsuranceDocumentValue] = useState("");
  const [showInsuranceImageModel, setshowInsuranceImageModel] = useState(false);
  const [showMOTImageModel, setshowMOTImageModel] = useState(false);
  const allowedFileTypes = ["image/png", "image/jpeg", "image/jpg", "image/gif"];
  const [insuranceImageSrc, setinsuranceImageSrc] = useState('');
  const [motImageSrc, setmotImageSrc] = useState('');

  React.useEffect(() => {
    VehicleValidation();
  }, []);

  const VehicleValidation = () => {

    if (vehicleDetailsData.taxdocument === null) {
      SettaxDocumentValue("");
    } else {
      SettaxDocumentValue(vehicleDetailsData.taxdocument.doC_D_FILE_NAME)
    }
    if (vehicleDetailsData.motdocument === null) {
      SetmotDocumentValue("");
    } else {
      SetmotDocumentValue(vehicleDetailsData.motdocument.doC_D_FILE_NAME);
    }
    if (vehicleDetailsData.insurancedocument === null) {
      SetinsuranceDocumentValue("")
    } else {
      SetinsuranceDocumentValue(vehicleDetailsData.insurancedocument.doC_D_FILE_NAME)
    }
  }

  const downloadFile = (e: any) => {
    var fileName = "";
    var fileType = "";
    var fileData = "";

    if (e.target.id === "insuranceDocument" && vehicleDetailsData.insurancedocument !== null) {
      fileName = vehicleDetailsData.insurancedocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.insurancedocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.insurancedocument.doC_D_FILE_DATA;

      var insuranceFileBinaryString = atob(fileData);
      var insuranceDowloadFileBytes = new Uint8Array(insuranceFileBinaryString.length);
      for (var i = 0; i < insuranceFileBinaryString.length; i++) {
        var insuranceAscii = insuranceFileBinaryString.charCodeAt(i);
        insuranceDowloadFileBytes[i] = insuranceAscii;
      }

      var insuranceFile = new Blob([insuranceDowloadFileBytes], {
        type: fileType,
      });

      var insuranceFileObjectURL = URL.createObjectURL(insuranceFile);

      if (allowedFileTypes.includes(fileType)) {
        setinsuranceImageSrc(insuranceFileObjectURL);
        setshowInsuranceImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = insuranceFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }

    if (e.target.id === "motDocument" && vehicleDetailsData.motdocument !== null) {
      fileName = vehicleDetailsData.motdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.motdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.motdocument.doC_D_FILE_DATA;

      var motFileBinaryString = atob(fileData);
      var motDowloadFileBytes = new Uint8Array(motFileBinaryString.length);
      for (var j = 0; j < motFileBinaryString.length; j++) {
        var motAscii = motFileBinaryString.charCodeAt(j);
        motDowloadFileBytes[j] = motAscii;
      }

      var motFile = new Blob([motDowloadFileBytes], {
        type: fileType,
      });
      var motFileObjectURL = URL.createObjectURL(motFile);

      if (allowedFileTypes.includes(fileType)) {
        setmotImageSrc(motFileObjectURL);
        setshowMOTImageModel(true);
      } else {
        const link = document.createElement("a");
        link.href = motFileObjectURL;
        link.download = fileName;
        link.click();
      }
    }
    if (e.target.id === "TaxDocument" && vehicleDetailsData.taxdocument !== null) {
      fileName = vehicleDetailsData.taxdocument.doC_D_FILE_NAME;
      fileType = vehicleDetailsData.taxdocument.doC_D_FILE_CONTENT_TYPE;
      fileData = vehicleDetailsData.taxdocument.doC_D_FILE_DATA;

      var taxFileBinaryString = atob(fileData);
      var taxDowloadFileBytes = new Uint8Array(taxFileBinaryString.length);
      for (var k = 0; k < taxFileBinaryString.length; k++) {
        var taxAscii = taxFileBinaryString.charCodeAt(k);
        taxDowloadFileBytes[k] = taxAscii;
      }

      var taxFile = new Blob([taxDowloadFileBytes], {
        type: fileType,
      });
      var taxFileObjectURL = URL.createObjectURL(taxFile);
      const link = document.createElement("a");
      link.href = taxFileObjectURL;
      link.download = fileName;
      link.click();
    }
  };

  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Modal
          visible={openVehicleDetailScreen}
          accent="teal"
          heading="Vehicle Details"
          cancelButtonProps={{ text: "Cancel" }}
          primaryButtonProps={{ buttonStyle: { display: "none" } }}
          onClose={() => { SetopenVehicleDetailScreen(false); }}
          onCancelButtonClick={onCancelButtonClick}
        >
          <div className="veh-Content">
            <FormLayout columns={3} rows={6}>
              <div style={{ gridColumnEnd: 'span 1' }}>
                <H5 style={{ fontWeight: "700", textAlign: "center" }}>Vehicle</H5>
              </div>
              <div style={{ gridColumnEnd: 'span 1' }}>
                <H5 style={{ fontWeight: "700", textAlign: "center" }}>Insurance</H5>
              </div>
              <div style={{ gridColumnEnd: 'span 1' }}>
                <H5 style={{ fontWeight: "700", textAlign: "center" }}>Tax & MOT</H5>
              </div>

              <FormField
                label="Registration Number"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Vehicle-Number" value={vehicleDetailsData.registertioN_NUMBER} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Insurance Term"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Insurance-Term" value={insTerm} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Tax Due Date"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-tax-due-date" value={taxDueDate} style={{ width: '100%' }} />}
                disabled
              />

              <FormField
                label="Registered"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Registration-Month" value={vehicleDetailsData.montH_OF_REGISTRATION} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Insurance Status"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-ins-status" value={vehicleDetailsData.insurancE_STATUS} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Tax Status"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Status" value={vehicleDetailsData.taX_STATUS} style={{ width: '100%' }} />}
                disabled
              />

              <FormField
                label="Fuel Type"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Fuel-Type" value={vehicleDetailsData.fueL_TYPE} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Insurance End Date"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-ins-expiry" value={insExpiryDate} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="MOT Expiry Date"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-mot-expiry-date" value={motExpiryDate} style={{ width: '100%' }} />}
                disabled
              />

              <FormField
                label="CO2 Emissions"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-emisson" value={vehicleDetailsData.cO2EMISSIONS} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Insurance Policy No."
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-ins-policy-no" value={vehicleDetailsData.insurancE_POLICYNUMBER} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="MOT Status"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-mot-status" value={vehicleDetailsData.moT_STATUS} style={{ width: '100%' }} />}
                disabled
              />

              <FormField
                label="Engine Capacity"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<Input name="veh-Engine-Capacity" value={vehicleDetailsData.enginE_CAPACITY} style={{ width: '100%' }} />}
                disabled
              />
              <FormField
                label="Insurance Document"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="insuranceDocument">{insuranceDocumentValue}</a>}
                disabled
              />
              <FormField
                label="MOT Document"
                labelProps={{ style: { fontWeight: '500' } }}
                editor={<a onClick={downloadFile} style={{ cursor: "pointer" }} id="motDocument">{motDocumentValue}</a>}
                disabled
              />
            </FormLayout>
          </div>
        </Modal>
      )
      }
      <div>
        {showInsuranceImageModel ? (
          <>
            <div>
              <Modal
                visible
                accent="teal"
                heading="Insurance Document"
                cancelButtonProps={{ buttonStyle: { display: "none" } }}
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onClose={() => { setshowInsuranceImageModel(false); }}
                scroll="content"
              >
                <div style={{ textAlign: "center" }}>
                  <img id="insuranceDocumentFileImage" src={insuranceImageSrc} alt="Insurance Document" />
                </div>
              </Modal>
            </div>
          </>
        ) : null}

        {showMOTImageModel ? (
          <>
            <div>
              <Modal
                visible
                accent="teal"
                heading="MOT Document"
                cancelButtonProps={{ buttonStyle: { display: "none" } }}
                primaryButtonProps={{ buttonStyle: { display: "none" } }}
                onClose={() => { setshowMOTImageModel(false); }}
                scroll="content"
              >
                <div style={{ textAlign: "center", maxHeight: "500px", overflowY: "scroll" }}>
                  <img id="motDocumentFileImage" src={motImageSrc} alt="MOT Doucment" />
                </div>
              </Modal>
            </div>
          </>
        ) : null}
      </div>
    </div >
  );
}
export default VehicleDetails;
