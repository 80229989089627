/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from "react";
import User from "./User";
import Approval from "./Approval";
import Configuration from "./Configuration";
import Dashboard from "./Dashboard";
import Notification from "./Notification";
import ArchivedUsers from "./ArchivedUsers";
import axios from "axios";
import { useIdentity } from "@workspace/identity-react";
import { PidgetSettings } from "../PidgetSettings";
import { useIframePidgetSettings } from "@workspace/utils-react/dist/iframePidget/useIframePidgetSettings";
import { useIframePidgetInstance } from '@workspace/utils-react';
import { LeftNavigation, LeftNavigationBody, LeftNavigationHeader, LeftNavigationItem, LeftNavigationItemButton, LeftNavigationItemContent, LeftNavigationPanel } from "@tag/tag-components-react-v4";
import { CheckboxTickedFilled, FolderArchiveFilled, Piechart2Filled, SettingsFilled, UsersFilled } from "@tag/tag-icons";
import "../assets/css/menu.scss";
import "../assets/css/common.scss";

const Menu = () => {
  const [openUserScreen, SetopenUserScreen] = useState(false);
  const [openApprovalScreen, SetopenApprovalScreen] = useState(false);
  const [openConfigurationScreen, SetopenConfigurationScreen] = useState(false);
  const [openDashboardScreen, SetopenDashboardScreen] = useState(true);
  const [openNotificationScreen, SetopenNotificationScreen] = useState(false);
  const [openArchivedUsersScreen, SetopenArchivedUsersScreen] = useState(false);
  const [chartName, SetchartName] = useState("");
  const [chartValue, SetchartValue] = useState("");
  const [chartlevelHigh, SetchartlevelHigh] = useState("");
  const [chartlevelLow, SetchartlevelLow] = useState("");
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [breadcrumb, setBreadcrumb] = useState("Dashboard");
  const [userStatus, setuserStatus] = useState("");

  // get the API url and instanceId so we can globally setup axios
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const { instanceId } = useIframePidgetInstance();
  const { getAccessToken } = useIdentity();
  const logoURL = url + "Content/Images/AccessDutyOfCareLogo.png";

  axios.defaults.baseURL = url;
  axios.interceptors.request.use(
    async req => {
      const accessToken = await getAccessToken();
      req.headers['Authorization'] = 'Bearer ' + accessToken;
      req.headers['X-Instance-Id'] = instanceId;
      req.baseURL = url;
      return req
    },
    error => {
      Promise.reject(error)
    }
  )

  const onNavClick = (e: any) => {
    if (e === "Dashboard") {
      SetopenDashboardScreen(true);
      SetopenUserScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Dashboard");
      setBreadcrumb("Dashboard");
      setuserStatus("");
    } else if (e === "Users") {
      fromDashBoard("", "", "", "");
      fromuser("");
      SetopenUserScreen(true);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Users");
      setBreadcrumb("Users");
      setuserStatus("");
    } else if (e === "Configuration") {
      SetopenConfigurationScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenNotificationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Configuration");
      setBreadcrumb("Configuration");
      setuserStatus("");
    } else if (e === "Notification") {
      SetopenNotificationScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenApprovalScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Notification");
      setBreadcrumb("Notification");
      setuserStatus("");
    } else if (e === "Approval") {
      SetopenApprovalScreen(true);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenArchivedUsersScreen(false);
      SetopenArchivedUsersScreen(false);
      setSelectedItem("Approval");
      setBreadcrumb("Approve / Reject");
      setuserStatus("");
    } else if (e === "ArchivedUsers") {
      SetopenApprovalScreen(false);
      SetopenUserScreen(false);
      SetopenDashboardScreen(false);
      SetopenConfigurationScreen(false);
      SetopenNotificationScreen(false);
      SetopenArchivedUsersScreen(true);
      setSelectedItem("ArchivedUsers");
      setBreadcrumb("Archived / Unarchived Users");
      setuserStatus("");
    }
  };

  const fromDashBoard = (
    chartName: any,
    chartValue: any,
    levelHigh: any,
    levelLow: any
  ) => {
    SetchartName(chartName);
    SetchartValue(chartValue);
    SetchartlevelHigh(levelHigh);
    SetchartlevelLow(levelLow);
    setSelectedItem("Users");
    setBreadcrumb("Dashboard");
    SetopenUserScreen(true);
    SetopenDashboardScreen(false);
    SetopenConfigurationScreen(false);
    SetopenNotificationScreen(false);
    SetopenApprovalScreen(false);
    SetopenArchivedUsersScreen(false);
  };


  const fromuser = (status: any) => {
    SetopenUserScreen(true);
    fromDashBoard("", "", "", "");
    setSelectedItem("Users");
    setBreadcrumb("Dashboard");
    SetopenDashboardScreen(false);
    SetopenConfigurationScreen(false);
    SetopenNotificationScreen(false);
    SetopenApprovalScreen(false);
    SetopenArchivedUsersScreen(false);
    setuserStatus(status);
  }

  return (
    <div className="menu common">
      <ul className="breadcrumb embedded-crumb" aria-label="Current page my settings">
        <li className="product-icon">
          <img id="licenceDocumentFileImage" src={logoURL} alt="Logo" />
        </li>
        <li className="active">{breadcrumb}</li>
      </ul>
      <div className="menu-container">
        <LeftNavigation defaultCollapsed background="light" accent="teal" selectedItem={selectedItem} onSelectedItemChange={onNavClick}>
          <LeftNavigationHeader>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span style={{ marginLeft: '5px', fontWeight: "bold" }}>Duty Of Care - Admin App</span>
            </div>
          </LeftNavigationHeader>
          <LeftNavigationBody style={{ height: '600px', display: 'flex', flexDirection: 'column' }}>
            <LeftNavigationPanel style={{ marginBottom: 'auto', borderTop: '1px solid #e7e7e7' }}>
              <LeftNavigationItem name="Dashboard">
                <LeftNavigationItemButton>
                  <LeftNavigationItemContent icon={<Piechart2Filled />} text="Dashboard" />
                </LeftNavigationItemButton>
              </LeftNavigationItem>
              <LeftNavigationItem name="Users">
                <LeftNavigationItemButton>
                  <LeftNavigationItemContent icon={<UsersFilled />} text="Users" />
                </LeftNavigationItemButton>
              </LeftNavigationItem>
              <LeftNavigationItem name="ArchivedUsers">
                <LeftNavigationItemButton>
                  <LeftNavigationItemContent icon={<FolderArchiveFilled />} text="Archived / Unarchived Users" />
                </LeftNavigationItemButton>
              </LeftNavigationItem>
              <LeftNavigationItem name="Configuration">
                <LeftNavigationItemButton>
                  <LeftNavigationItemContent icon={<SettingsFilled />} text="Configuration" />
                </LeftNavigationItemButton>
              </LeftNavigationItem>
              <LeftNavigationItem name="Approval">
                <LeftNavigationItemButton>
                  <LeftNavigationItemContent icon={<CheckboxTickedFilled />} text="Approve / Reject" />
                </LeftNavigationItemButton>
              </LeftNavigationItem>
            </LeftNavigationPanel>
          </LeftNavigationBody>
        </LeftNavigation>
      </div>
      <div className="doc_container w-100">
        {openUserScreen ? (
          <User
            openUserScreen={openUserScreen}
            SetopenUserScreen={SetopenUserScreen}
            chartName={chartName}
            chartValue={chartValue}
            levelHigh={chartlevelHigh}
            levelLow={chartlevelLow}
            userStatus={userStatus}
          />
        ) : null}
        {openArchivedUsersScreen ? (
          <ArchivedUsers
            openArchivedUsersScreen={openArchivedUsersScreen}
            SetopenArchivedUsersScreen={SetopenArchivedUsersScreen}
          />
        ) : null}
        {openApprovalScreen ? (
          <Approval
            openApprovalScreen={openApprovalScreen}
            SetopenApprovalScreen={SetopenApprovalScreen}
          />
        ) : null}
        {openConfigurationScreen ? (
          <Configuration
            openConfigurationScreen={openConfigurationScreen}
            SetopenConfigurationScreen={SetopenConfigurationScreen}
          />
        ) : null}
        {openDashboardScreen ? (
          <Dashboard
            openDashboardScreen={openDashboardScreen}
            SetopenDashboardScreen={SetopenDashboardScreen}
            fromDashBoard={fromDashBoard}
            fromuser={fromuser}
          />
        ) : null}
        {openNotificationScreen ? (
          <Notification
            openNotificationScreen={openNotificationScreen}
            SetopenNotificationScreen={SetopenNotificationScreen}
          />
        ) : null}
      </div>
    </div>
  );
};
export default Menu;
