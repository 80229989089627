/* eslint-disable no-dupe-else-if */

import React from 'react';
function Notification({ openNotificationScreen, SetopenNotificationScreen }: any) {
    return (
        openNotificationScreen ? <h1>Notification screen</h1> : null
    );
}
export default Notification;

