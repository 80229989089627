/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-dupe-else-if */

import axios from "axios";
import React from "react";
import Chart from "../reusable-Components/Chart";
import ExpiredChart from "../reusable-Components/ExpiredChart";
import { HttpHeaders } from "../shared/HttpHeaders";
import { Col, Container, Row } from "react-bootstrap";
import LoadingSpinner from "./LoadingSpinner";
import { ProgressDonut } from "@tag/tag-components-react-v4";
import "../assets/css/config.scss";
import "../assets/css/common.scss";

function Dashboard({
  openDashboardScreen,
  SetopenDashboardScreen,
  fromDashBoard,
  fromuser,
}: any) {
  const Headers = HttpHeaders();
  const [accessCount, setaccessCount] = React.useState(0);
  const [noAccessCount, setnoAccessCount] = React.useState(0);
  const [notUsedCount, setnotUsedCount] = React.useState(0);
  const [manuallyVehicleCount, setmanuallyVehicleCount] = React.useState(0);
  const [approvedmanuallyVehicleCount, setapprovedmanuallyVehicleCount] = React.useState(0);
  const [pendingmanuallyVehicleCount, setpendingmanuallyVehicleCount] = React.useState(0);
  const [rejectmanuallyVehicleCount, setrejectmanuallyVehicleCount] = React.useState(0);
  const [manuallyLicenceCount, setmanuallyLicenceCount] = React.useState(0);
  const [approvedmanuallyLicenceCount, setapprovedmanuallyLicenceCount] = React.useState(0);
  const [pendingmanuallyLicenceCount, setpendingmanuallyLicenceCount] = React.useState(0);
  const [rejectmanuallyLicenceCount, setrejectmanuallyLicenceCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    getUserWiseCount();
  }, []);

  function getUserWiseCount() {
    setIsLoading(true);
    axios.get(`User/GetUsersStatusWiseCount`, Headers).then((response) => {
      if (response !== null && response !== undefined) {
        setaccessCount(response.data["data"].accessUser);
        setnoAccessCount(response.data["data"].noAccessUser);
        setnotUsedCount(response.data["data"].notUsedUser);
      }
      GetManualViehicleStatusWiseCount();
    }).catch((ex) => {
      console.log("Getting some API error when bind archived users details!");
      setIsLoading(false);
    });
  }

  function onUserwiseCountClick(e: any) {
    if (e.target.id === "access") {
      fromuser("Access");
    } else if (e.target.id === "notAccess") {
      fromuser("No Access");
    } else {
      fromuser("Not Used");
    }
  }

  function GetManualViehicleStatusWiseCount() {
    axios.get(`vehicle/GetManualViehicleStatusWiseCount`, Headers).then((response) => {
      if (response !== null && response !== undefined) {
        setmanuallyVehicleCount(response.data["data"].total);
        setapprovedmanuallyVehicleCount(response.data["data"].approved);
        setpendingmanuallyVehicleCount(response.data["data"].pending);
        setrejectmanuallyVehicleCount(response.data["data"].rejected)
      }
      GetManualLicenceStatusWiseCount();
    }).catch((ex) => {
      console.log("Getting some API error when bind archived users details!");
      setIsLoading(false);
    });
  }

  function GetManualLicenceStatusWiseCount() {
    axios.get(`licence/GetManualLicenceStatusWiseCount`, Headers).then((response) => {
      if (response !== null && response !== undefined) {
        setmanuallyLicenceCount(response.data["data"].total);
        setapprovedmanuallyLicenceCount(response.data["data"].approved);
        setpendingmanuallyLicenceCount(response.data["data"].pending);
        setrejectmanuallyLicenceCount(response.data["data"].rejected);
      }
      setIsLoading(false);
    }).catch((ex) => {
      console.log("Getting some API error when bind archived users details!");
      setIsLoading(false);
    });
  }

  return openDashboardScreen ? (
    <>
      <div id="Dashboard">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div id="Chart-Driving-Licence" className="Chart">
              <div id="DrivingLicence" className="tagCenter chart fl b_1_s_border">
                <Chart
                  chartName="Driving Licence"
                  chartAPIName="GetLicenceChart"
                  chartDetailAPIName="GetLicenceChartDetails"
                  openDashboardScreen={openDashboardScreen}
                  SetopenDashboardScreen={SetopenDashboardScreen}
                  fromDashBoard={fromDashBoard}
                />
              </div>
              <div id="Tax" className="tagCenter chart fl b_1_s_border">
                <Chart
                  chartName="Tax Due"
                  chartAPIName="GetTaxChart"
                  chartDetailAPIName="GetTaxChartDetails"
                  openDashboardScreen={openDashboardScreen}
                  SetopenDashboardScreen={SetopenDashboardScreen}
                  fromDashBoard={fromDashBoard}
                />
              </div>
              <div id="Mot" className="tagCenter chart fl b_1_s_border">
                <Chart
                  chartName="MOT"
                  chartAPIName="GetMotChart"
                  chartDetailAPIName="GetMotChartDetails"
                  openDashboardScreen={openDashboardScreen}
                  SetopenDashboardScreen={SetopenDashboardScreen}
                  fromDashBoard={fromDashBoard}
                />
              </div>
              <div id="Insurance" className="tagCenter chart fl b_1_s_border">
                <Chart
                  chartName="Insurance"
                  chartAPIName="GetInsuranceChart"
                  chartDetailAPIName="GetInsuranceChartDetails"
                  openDashboardScreen={openDashboardScreen}
                  SetopenDashboardScreen={SetopenDashboardScreen}
                  fromDashBoard={fromDashBoard}
                />
              </div>
              <div id="Expired" className="tagCenter chart fl b_1_s_border">
                <ExpiredChart
                  chartName="Expired"
                  chartAPIName="GetExpiredChart"
                  chartDetailAPIName="GetExpiredChartDetails"
                  openDashboardScreen={openDashboardScreen}
                  SetopenDashboardScreen={SetopenDashboardScreen}
                  fromDashBoard={fromDashBoard}
                />
              </div>
              <div id="Expired" className="tagCenter chart fl b_1_s_border">
                <div>
                  <Container className="ConfigurationBorder" style={{ width: "398px" }}>
                    <div style={{ paddingTop: "10px" }} className="text-center p_10">
                      <span style={{ color: "#666666", fontWeight: "700", fontSize: "17px", paddingLeft: "10px" }}>Manually Added Licences</span>
                    </div>
                    <div >
                      <div className="mt_10">
                        <Row style={{ marginTop: "-15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold">
                            <ProgressDonut
                              value={manuallyLicenceCount}
                              width='90px'
                              accent='teal' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={rejectmanuallyLicenceCount}
                              width='90px'
                              accent='purple' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={pendingmanuallyLicenceCount}
                              width='90px'
                              accent='teal' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={approvedmanuallyLicenceCount}
                              width='90px'
                              accent='teal'
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "-15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10">
                            <span>Total</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Rejected</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Unverified</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Verified</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Container>
                  <Container className="ConfigurationBorder" style={{ width: "398px" }}>
                    <div style={{ paddingTop: "10px" }} className="text-center p_10">
                      <span style={{ color: "#666666", fontWeight: "700", fontSize: "17px", paddingLeft: "10px" }}>Manually Added Vehicles</span>
                    </div>
                    <div >
                      <div className="mt_10">
                        <Row style={{ marginTop: "-15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold">
                            <ProgressDonut
                              value={manuallyVehicleCount}
                              width='90px'
                              accent='teal' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={rejectmanuallyVehicleCount}
                              width='90px'
                              accent='purple' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={pendingmanuallyVehicleCount}
                              width='90px'
                              accent='teal' />
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ marginLeft: "-4px" }}>
                            <ProgressDonut
                              value={approvedmanuallyVehicleCount}
                              width='90px'
                              accent='teal'
                            />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "-15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10">
                            <span>Total</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Rejected</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Unverified</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ marginLeft: "-4px" }}>
                            <span>Verified</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Container>
                  <Container className="ConfigurationBorder" style={{ width: "398px" }}>
                    <div style={{ paddingTop: "10px" }} className="text-center p_10">
                      <span style={{ color: "#666666", fontWeight: "700", fontSize: "17px", paddingLeft: "10px" }}>User Stats</span>
                    </div>
                    <div >
                      <div className="mt_10">
                        <Row style={{ marginTop: "-13px", marginBottom: "15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ width: "128px", color: "#4b4e52" }}>
                            <a onClick={onUserwiseCountClick}>
                              <span id="access" style={{ fontSize: "48px" }}>{accessCount}</span>
                            </a>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ width: "128px", color: "#4b4e52" }}>
                            <a onClick={onUserwiseCountClick}>
                              <span id="notAccess" style={{ fontSize: "48px" }}>{noAccessCount}</span>
                            </a>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10 fw-bold" style={{ width: "128px", color: "#4b4e52" }}>
                            <a onClick={onUserwiseCountClick}>
                              <span style={{ fontSize: "48px" }}>{notUsedCount}</span>
                            </a>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "-15px" }}>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ width: "128px" }}>
                            <span>Access</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ width: "128px" }}>
                            <span>No Access</span>
                          </Col>
                          <Col xs={3} sm={3} xl={3} className="text-center p_10" style={{ width: "128px" }}>
                            <span>Not Used</span>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Container>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  ) : null;
}
export default Dashboard;
