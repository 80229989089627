import React from "react";
import { Confirm, DataTable, Modal } from "@tag/tag-components-react-v4";

function ManualLicenceVehicleHistory({
    openLicenceHistoryScreen,
    licenceHistoryDetailsData,
    SetopenLicenceHistoryScreen,
    fromLicenceHistoryScreen,
    SetshowManualLicenceHistory,
    LicenceVehicleHistoryPopUp
}: any) {
    const data: any = [];
    const onCancelButtonClick = (e: any) => {
        SetopenLicenceHistoryScreen(false);
        SetshowManualLicenceHistory(false);
    };

    const columns = React.useMemo(
        () => [
            { accessorKey: 'userName', header: 'Performing User' },
            { accessorKey: 'status', header: 'Status' },
            { accessorKey: 'rejectReason', header: 'Reject Reason' },
            { accessorKey: 'performingDate', header: 'Performing Date', meta: { dataType: 'date' } }],
        [],
    );

    for (let i = 0; i < licenceHistoryDetailsData.length; i++) {
        let performingDate = new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getDate() + "-" + (new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getMonth() + 1) + "-" + new Date(licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_DATE).getFullYear();
        data.push({
            userName: licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_USER_NAME === null ? '-' : licenceHistoryDetailsData[i].doC_DVLA_MAH_PERFORMING_USER_NAME,
            rejectReason: licenceHistoryDetailsData[i].doC_DVLA_MAH_REJECTION_REASON === "" ? '-' : licenceHistoryDetailsData[i].doC_DVLA_MAH_REJECTION_REASON,
            status: licenceHistoryDetailsData[i].doC_DVLA_MAH_MODE === 0 ? "Reject" : "Verified",
            performingDate: performingDate
        });
    }

    return (
        <>
            <div>
                <Modal
                    visible={openLicenceHistoryScreen}
                    accent="teal"
                    heading={fromLicenceHistoryScreen === true ? "Licence History Details" : "Vehicle History Details"}
                    primaryButtonProps={{ buttonStyle: { display: "none" } }}
                    cancelButtonProps={{ text: "Close" }}
                    onCancelButtonClick={onCancelButtonClick}
                    suppressCloseButton>
                    <DataTable columns={columns} data={data} />
                </Modal>
            </div>
            <div>
                {LicenceVehicleHistoryPopUp === "true" ? (
                    <Confirm
                        visible
                        width="small"
                        type="information"
                        heading="Information"
                        cancelButtonProps={{ text: "Close" }}
                        primaryButtonProps={{ buttonStyle: { display: "none" } }}
                        onCancelButtonClick={onCancelButtonClick}
                        text={fromLicenceHistoryScreen === true ? "There is no history for this licence." : "There is no history for this vehicle."}
                        suppressCloseButton
                    />
                ) : null}
            </div>
        </>
    );
}
export default ManualLicenceVehicleHistory;