/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useIframePidgetSettings } from "@workspace/utils-react";
import axios from "axios";
import React, { useRef, useState } from "react";
import { IArchiveUsersFilter, IUsers, IUsersDetails } from "../core/view-Model/users.model";
import { PidgetSettings } from "../PidgetSettings";
import { HttpHeaders } from "../shared/HttpHeaders";
import LoadingSpinner from "./LoadingSpinner";
import { NotifyPublisher } from "./Notify/NotifyPublisher";
import UserDetails from "./UserDetails/UserDetails";
import { P, TabContent, TabHeader, TabHeaders, Tabs, SquareButton, Button, DataTable, Checkbox, Confirm, Pager, Avatar, Modal, FormLayout, FormField, Input } from "@tag/tag-components-react-v4";
import { MicrosoftExcelFilled, SwitchesFilled, ViewFilled } from "@tag/tag-icons";
import "../assets/css/ArchivedUsers.scss";

function ArchivedUsers({
  openArchivedUsersScreen,
  SetopenArchivedUsersScreen,
}: any) {
  const Headers = HttpHeaders();
  const pageSize = 10;
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = React.useState(1);
  const [archivedUsersScreen, SetArchivedUsersScreen] = useState(false);
  const [allArchivedUsersData, setAllArchivedUsersData] = useState<IUsers[]>([]);
  const archivedUserCheckedData = useRef<IUsers[]>([]);
  const [allUnarchivedUsersData, setAllUnarchivedUsersData] = useState<IUsers[]>([]);
  const unArchivedUserCheckedData = useRef<IUsers[]>([]);
  const [openArchiveduserTabScreen, SetOpenArchiveduserTabScreen] = useState(true);
  const [openUnarchiveduserTabScreen, SetOpenUnarchiveduserTabScreen] = useState(false);
  const [openDetailScreen, SetopenopenDetailScreen] = useState(false);
  const [openArchiveFilterScreen, SetOpenArchiveFilterScreen] = useState(false);
  const [openUnarchiveFilterScreen, SetOpenUnarchiveFilterScreen] = useState(false);
  const [isdisabled, setdisabled] = useState<boolean>(true);
  const [showArchiveUserConfirmationPopup, setshowArchiveUserConfirmationPopup] = useState(false);
  const [showArchiveUserConfirmationDialogue, setshowArchiveUserConfirmationDialogue] = useState(false);

  const OnArchiveFilterOpened = (e: any) => { };
  const OnUnarchiveFilterOpened = (e: any) => { };
  const [archivedUserFilterData, setArchivedUserFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });

  const [archivedUserApplyFilterData, setArchivedUserApplyFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });

  const [unarchivedUserFilterData, setUnarchivedUserFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });

  const [unarchivedUserApplyFilterData, setUnarchivedUserApplyFilterData] =
    React.useState<IArchiveUsersFilter>({
      Name: "",
      JobTitle: "",
      Office: "",
      Department: "",
      Pagesize: pageSize,
      CurrentPageNumber: 1,
      GetAll: false,
    });

  const [isLoading, setIsLoading] = React.useState(false);
  const { apiUrl: url } = useIframePidgetSettings<PidgetSettings>();
  const userAvtar = url + "Content/Images/useravtar.png";

  React.useEffect(() => {
    LoadAllArchiveUsers(1);
  }, []);

  const [archiveUsersFilterCount, setarchiveUsersFilterCount] = React.useState('0');
  const [unarchiveUsersFilterCount, setunarchiveUsersFilterCount] = React.useState('0');
  const handleArchiveUserPageChangeRequest = (e: any) => { LoadAllArchiveUsers(e.page); };
  const handleUnArchiveUserPageChangeRequest = (e: any) => { LoadAllUnarchiveUsers(e.page); };

  const ArchivedRowchecked = (e: any, checkValue: any) => {
    if (checkValue === true && e.row.original !== undefined) {
      const archiveDetailIndex = archivedUserCheckedData.current.filter(x => x.userId === e.row.original.userId);
      if (archiveDetailIndex.length === 0) {
        archivedUserCheckedData.current.push(e.row.original);
      }
    } else {
      const archiveDetailIndex = archivedUserCheckedData.current.indexOf(e.row.original);
      archivedUserCheckedData.current.splice(archiveDetailIndex, 1);
    }

    if (archivedUserCheckedData.current.length > 0) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  };

  // const UnArchivedRowchecked = (e: any) => { setUnArchivedUserCheckedData(e.detail.checkedRows); };
  const UnArchivedRowchecked = (e: any, checkValue: any) => {
    if (checkValue === true && e.row.original !== undefined) {
      const unArchiveDetailIndex = unArchivedUserCheckedData.current.filter(x => x.userId === e.row.original.userId);
      if (unArchiveDetailIndex.length === 0) {
        unArchivedUserCheckedData.current.push(e.row.original);
      }
    } else {
      const unArchiveDetailIndex = unArchivedUserCheckedData.current.indexOf(e.row.original);
      unArchivedUserCheckedData.current.splice(unArchiveDetailIndex, 1);
    }

    if (unArchivedUserCheckedData.current.length > 0) {
      setdisabled(false);
    } else {
      setdisabled(true);
    }
  };

  const OnArchiveFilterClick = (e: any) => { SetOpenArchiveFilterScreen(true); };
  const OnUnarchiveFilterClick = (e: any) => { SetOpenUnarchiveFilterScreen(true); };

  const LoadAllArchiveUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllArchivedUsersData([]);
    archivedUserApplyFilterData.GetAll = false;
    archivedUserApplyFilterData.CurrentPageNumber = currentPage;
    axios
      .post(
        `User/GetAllLocalArchivedUsersDetailsList`,
        archivedUserApplyFilterData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setAllArchivedUsersData(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenArchiveFilterScreen(false);
                setdisabled(true);
                archivedUserCheckedData.current = [];
                setIsLoading(false);
              }
              else {
                setdisabled(true);
                setIsLoading(false);
                SetOpenArchiveFilterScreen(false);
              }
            } else {
              setIsLoading(false);
              SetOpenArchiveFilterScreen(false);
            }
          } else {
            setIsLoading(false);
            SetOpenArchiveFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenArchiveFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
        SetOpenArchiveFilterScreen(false);
      });
  };

  const ExportAllArchiveUsers = () => {
    setIsLoading(true);
    archivedUserApplyFilterData.GetAll = true;
    archivedUserApplyFilterData.CurrentPageNumber = 1;
    axios
      .post(
        `User/GetAllLocalArchivedUsersDetailsExport`,
        archivedUserApplyFilterData,
        { responseType: 'blob' }
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "ArchivedUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when export archived users details!");
      });
  };

  const LoadAllUnarchiveUsers = (currentPage: number) => {
    setIsLoading(true);
    setAllUnarchivedUsersData([]);
    unarchivedUserApplyFilterData.GetAll = false;
    unarchivedUserApplyFilterData.CurrentPageNumber = currentPage;
    axios
      .post(
        `User/GetAllLocalUnarchivedUsersDetailsList`,
        unarchivedUserApplyFilterData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              if (response.data.data.length > 0) {
                setAllUnarchivedUsersData(response.data.data);
                UpdateUserAvtar();
                setPage(currentPage);
                setPageCount(response.data.totalPages);
                SetOpenUnarchiveFilterScreen(false);
                setdisabled(true);
                setshowArchiveUserConfirmationPopup(false);
                setIsLoading(false);
              }
              else {
                setdisabled(true);
                setIsLoading(false);
                SetOpenUnarchiveFilterScreen(false);
              }
            }
          } else {
            setIsLoading(false);
            SetOpenUnarchiveFilterScreen(false);
          }
        } else {
          setIsLoading(false);
          SetOpenUnarchiveFilterScreen(false);
        }
      })
      .catch((ex) => {
        console.log("Getting some API error when bind archived users details!");
        SetOpenUnarchiveFilterScreen(false);
      });
  };

  const ExportAllUnarchiveUsers = () => {
    setIsLoading(true);
    unarchivedUserApplyFilterData.GetAll = true;
    unarchivedUserApplyFilterData.CurrentPageNumber = 1;
    axios
      .post(
        `User/GetAllLocalUnarchivedUsersDetailsExport`,
        unarchivedUserApplyFilterData,
        { responseType: 'blob' }
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            var fileName = "UnArchivedUsers";
            var fileType = response.data.type;
            var file = new Blob([response.data], { type: fileType });
            var archiveUsersURL = URL.createObjectURL(file);
            const link = document.createElement("a");

            link.href = archiveUsersURL;
            link.download = fileName;
            link.click();

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      })
      .catch((ex) => {
        setIsLoading(false);
        console.log("Getting some API error when export archived users details!");
      });
  };

  const OnArchiveFilterApplyClick = (e: any) => {
    let archiveCountOfFilter = 0;

    if (archivedUserFilterData.Name !== "" && archivedUserFilterData.Name !== null && archivedUserFilterData.Name !== undefined) {
      archiveCountOfFilter++;
      archivedUserApplyFilterData.Name = archivedUserFilterData.Name
    } else {
      archivedUserApplyFilterData.Name = archivedUserFilterData.Name
    }

    if (archivedUserFilterData.JobTitle !== "" && archivedUserFilterData.JobTitle !== null && archivedUserFilterData.JobTitle !== undefined) {
      archiveCountOfFilter++;
      archivedUserApplyFilterData.JobTitle = archivedUserFilterData.JobTitle;
    } else {
      archivedUserApplyFilterData.JobTitle = archivedUserFilterData.JobTitle;
    }

    if (archivedUserFilterData.Office !== "" && archivedUserFilterData.Office !== null && archivedUserFilterData.Office !== undefined) {
      archiveCountOfFilter++;
      archivedUserApplyFilterData.Office = archivedUserFilterData.Office;
    } else {
      archivedUserApplyFilterData.Office = archivedUserFilterData.Office;
    }

    if (archivedUserFilterData.Department !== "" && archivedUserFilterData.Department !== null && archivedUserFilterData.Department !== undefined) {
      archiveCountOfFilter++;
      archivedUserApplyFilterData.Department = archivedUserFilterData.Department;
    } else {
      archivedUserApplyFilterData.Department = archivedUserFilterData.Department;
    }

    setarchiveUsersFilterCount(String(archiveCountOfFilter));
    LoadAllArchiveUsers(1);
  };

  const OnUnarchiveFilterApplyClick = (e: any) => {
    let unArchiveCountOfFilter = 0;

    if (unarchivedUserFilterData.Name !== "" && unarchivedUserFilterData.Name !== null && unarchivedUserFilterData.Name !== undefined) {
      unArchiveCountOfFilter++;
      unarchivedUserApplyFilterData.Name = unarchivedUserFilterData.Name;
    } else {
      unarchivedUserApplyFilterData.Name = unarchivedUserFilterData.Name;
    }

    if (unarchivedUserFilterData.JobTitle !== "" && unarchivedUserFilterData.JobTitle !== null && unarchivedUserFilterData.JobTitle !== undefined) {
      unArchiveCountOfFilter++;
      unarchivedUserApplyFilterData.JobTitle = unarchivedUserFilterData.JobTitle;
    } else {
      unarchivedUserApplyFilterData.JobTitle = unarchivedUserFilterData.JobTitle;
    }

    if (unarchivedUserFilterData.Office !== "" && unarchivedUserFilterData.Office !== null && unarchivedUserFilterData.Office !== undefined) {
      unArchiveCountOfFilter++;
      unarchivedUserApplyFilterData.Office = unarchivedUserFilterData.Office;
    } else {
      unarchivedUserApplyFilterData.Office = unarchivedUserFilterData.Office;
    }

    if (unarchivedUserFilterData.Department !== "" && unarchivedUserFilterData.Department !== null && unarchivedUserFilterData.Department !== undefined) {
      unArchiveCountOfFilter++;
      unarchivedUserApplyFilterData.Department = unarchivedUserFilterData.Department;
    } else {
      unarchivedUserApplyFilterData.Department = unarchivedUserFilterData.Department;
    }

    setunarchiveUsersFilterCount(String(unArchiveCountOfFilter));
    LoadAllUnarchiveUsers(1);
  };

  const OnUnarchiveClick = (e: any) => {
    SetUnarchiveData(archivedUserCheckedData.current);
    SetArchivedUsersScreen(false);
  };

  const OnArchiveClick = (e: any) => {
    if (unArchivedUserCheckedData.current.length > 1) {
      setshowArchiveUserConfirmationDialogue(false);
    } else {
      setshowArchiveUserConfirmationDialogue(true);
    }
    setshowArchiveUserConfirmationPopup(true);
  };

  const SetUnarchiveData = (archivedCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetUsersUnarchive`,
        archivedCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('Users have been un-archived.'), false, 'success', 'Success', '');
              setAllArchivedUsersData([]);
              archivedUserCheckedData.current = [];
              LoadAllArchiveUsers(1);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
          }
        }
      });
  };

  const SetArchiveData = (unArchivedCheckedData: IUsers[]) => {
    setIsLoading(true);
    axios
      .post(
        `User/SetUsersArchive`,
        unArchivedCheckedData,
        Headers
      )
      .then((response) => {
        if (response !== null && response !== undefined) {
          if (response.data !== null && response.data !== undefined) {
            if (
              response.data.data !== null &&
              response.data.data !== undefined
            ) {
              NotifyPublisher.message(('Users have been archived.'), false, 'success', 'Success', '');
              setAllUnarchivedUsersData([]);
              unArchivedUserCheckedData.current = [];
              LoadAllUnarchiveUsers(1);
            } else {
              setIsLoading(false);
            }
          }
        } else {
          setIsLoading(false);
        }
      });
  };

  const UpdateUserAvtar = () => {
    setAllUnarchivedUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
    setAllArchivedUsersData((x) =>
      [...x].map((i) =>
        i.avatarURL320 == null
          ? { ...i, avatarURL320: userAvtar }
          : i
      )
    );
  };

  const OnTabChange = (e: any) => {
    if ((e === "ArchivedUsers") || (typeof (e) == "object" ? e.detail.name == 'ArchivedUsers' : false)) {
      LoadAllArchiveUsers(1);
      SetOpenArchiveduserTabScreen(true);
      SetOpenUnarchiveduserTabScreen(false);
    } else if ((e === "UnarchivedUsers") || (typeof (e) == "object" ? e.detail.name == 'UnarchivedUsers' : false)) {
      LoadAllUnarchiveUsers(1);
      SetOpenUnarchiveduserTabScreen(true);
      SetOpenArchiveduserTabScreen(false);
    }
  };

  //UnComment when allow to view manually added
  // function onArchiveRowClick(newValue: any) {
  //   if (!newValue) {
  //     OnTabChange({ detail: { name: "ArchivedUsers" } });
  //   }
  //   SetArchivedUsersScreen(newValue);
  // }

  //UnComment when allow to view manually added
  // function onUnArchiveRowClick(newValue: any) {
  //   if (!newValue) {
  //     OnTabChange({ detail: { name: "ArchivedUsers" } });
  //   }
  //   SetArchivedUsersScreen(newValue);
  // }

  function onArchiveRowClick(newValue: any) {
    if (typeof (newValue) == "object") {
      let row = {};
      let detail = {};
      row = newValue.row.original;
      detail = { row }
      newValue = { detail }
      OnTabChange({ detail: { name: "ArchivedUsers" } });
      SetArchivedUsersScreen(newValue);
    }
    else {
      SetArchivedUsersScreen(newValue);
      LoadAllArchiveUsers(1);
    }
  }

  function onUnArchiveRowClick(newValue: any) {
    if (typeof (newValue) == "object") {
      let row = {};
      let detail = {};
      row = newValue.row.original;
      detail = { row }
      newValue = { detail }
      OnTabChange({ detail: { name: "ArchivedUsers" } });
      SetArchivedUsersScreen(newValue);
    }
    else {
      SetArchivedUsersScreen(newValue);
    }
  }

  const archiveUsercolumns = React.useMemo(() => [
    {
      accessorKey: 'Checkbox',
      header: () => <div style={{ textAlign: 'center' }}>Unarchive</div>,
      cell: archivedUserCheckbox,
      meta: { getCellProps: (cell: any) => { return { style: { textAlign: 'center', width: '50px' } }; }, },
    },
    {
      accessorKey: 'avatarURL320',
      header: 'User',
      meta: { dataType: 'image', getCellProps: (cell: any) => { return { style: { width: '25px' } }; } }
    },
    { accessorKey: 'fullName', header: 'Name' },
    { accessorKey: 'jobTitle', header: 'Job Title' },
    { accessorKey: 'office', header: 'Office' },
    { accessorKey: 'department', header: 'Department' },
    {
      accessorKey: 'View',
      header: () => <div style={{ textAlign: 'center', width: "40px" }}>View</div>,
      cell: archivedUserViewButton,
      meta: {
        getCellProps: (cell: any) => {
          return { style: { textAlign: 'center', width: '50px' } };
        },
      },
    }
  ], [],);

  const unArchiveUsercolumns = React.useMemo(() => [
    {
      accessorKey: 'Checkbox',
      header: () => <div style={{ textAlign: 'center' }}>Archive</div>,
      cell: unArchivedUserCheckbox,
      meta: { getCellProps: (cell: any) => { return { style: { textAlign: 'center', width: '50px' } }; }, },
    },
    { accessorKey: 'avatarURL320', header: 'User', meta: { dataType: 'image' } },
    { accessorKey: 'fullName', header: 'Name' },
    { accessorKey: 'jobTitle', header: 'Job Title' },
    { accessorKey: 'office', header: 'Office' },
    { accessorKey: 'department', header: 'Department' },
    {
      accessorKey: 'View',
      header: () => <div style={{ textAlign: 'center', width: "80px" }}>View</div>,
      cell: unarchivedUserViewButton,
      meta: { getCellProps: (cell: any) => { return { style: { textAlign: 'center', width: '50px' } }; }, },
    }
  ], [],);

  function archivedUserViewButton(row: any) {
    return (
      <SquareButton
        icon={<ViewFilled />}
        style={{ height: "24px", width: "24px", padding: "2px" }}
        onClick={() => onArchiveRowClick(row)}
      />
    );
  }

  function unarchivedUserViewButton(row: any) {
    return (
      <SquareButton
        icon={<ViewFilled />}
        style={{ height: "24px", width: "24px", padding: "2px" }}
        onClick={() => onUnArchiveRowClick(row)}
      />
    );
  }

  function archivedUserCheckbox(row: any) {
    return (
      <Checkbox onChange={(event: any) => { ArchivedRowchecked(row, event.target.value); }} />
    );
  }

  function unArchivedUserCheckbox(row: any) {
    return (
      <Checkbox onChange={(event: any) => { UnArchivedRowchecked(row, event.target.value); }} />
    );
  }

  const onBtnConfirmationCancelClick = (e: any) => {
    setshowArchiveUserConfirmationPopup(false);
  };

  const onBtnConfirmationSaveClick = (e: any) => {
    SetArchiveData(unArchivedUserCheckedData.current);
    SetArchivedUsersScreen(false);
    setshowArchiveUserConfirmationPopup(false);
  };

  let handleTextChange = (evt: any) => {
    if (evt.target.name === "usr-name") {
      setArchivedUserFilterData({
        ...archivedUserFilterData,
        Name: evt.target.value,
      });
    } if (evt.target.name === "usr-jobtitle") {
      setArchivedUserFilterData({
        ...archivedUserFilterData,
        JobTitle: evt.target.value,
      });
    } if (evt.target.name === "usr-office") {
      setArchivedUserFilterData({
        ...archivedUserFilterData,
        Office: evt.target.value,
      });
    }
    if (evt.target.name === "usr-department") {
      setArchivedUserFilterData({
        ...archivedUserFilterData,
        Department: evt.target.value,
      });
    }

    if (evt.target.name === "un-usr-name") {
      setUnarchivedUserFilterData({
        ...unarchivedUserFilterData,
        Name: evt.target.value,
      });
    } if (evt.target.name === "un-usr-jobtitle") {
      setUnarchivedUserFilterData({
        ...unarchivedUserFilterData,
        JobTitle: evt.target.value,
      });
    } if (evt.target.name === "un-usr-office") {
      setUnarchivedUserFilterData({
        ...unarchivedUserFilterData,
        Office: evt.target.value,
      });
    }
    if (evt.target.name === "un-usr-department") {
      setUnarchivedUserFilterData({
        ...unarchivedUserFilterData,
        Department: evt.target.value,
      });
    }
  };

  const resetArchiveUnarchiveUserDetailFilterOnClick = (e: string, isfrom: string) => {
    if (e.toLowerCase() === "clear") {
      if (isfrom == "fromArchiveUserFilter") {
        archivedUserFilterData.Name = "";
        archivedUserFilterData.JobTitle = "";
        archivedUserFilterData.Office = "";
        archivedUserFilterData.Department = "";

        archivedUserApplyFilterData.Name = "";
        archivedUserApplyFilterData.JobTitle = "";
        archivedUserApplyFilterData.Office = "";
        archivedUserApplyFilterData.Department = "";
        setarchiveUsersFilterCount('0');
        LoadAllArchiveUsers(1);
      } else {
        unarchivedUserFilterData.Name = "";
        unarchivedUserFilterData.JobTitle = "";
        unarchivedUserFilterData.Office = "";
        unarchivedUserFilterData.Department = "";

        unarchivedUserApplyFilterData.Name = "";
        unarchivedUserApplyFilterData.JobTitle = "";
        unarchivedUserApplyFilterData.Office = "";
        unarchivedUserApplyFilterData.Department = "";
        setunarchiveUsersFilterCount('0');
        LoadAllUnarchiveUsers(1);
      }
    } else {
      if (e.toLowerCase() === "close") {
        if (isfrom == "fromArchiveUserFilter") {
          SetOpenArchiveFilterScreen(false);
          archivedUserFilterData.Name = archivedUserApplyFilterData.Name;
          archivedUserFilterData.JobTitle = archivedUserApplyFilterData.JobTitle;
          archivedUserFilterData.Office = archivedUserApplyFilterData.Office;
          archivedUserFilterData.Department = archivedUserApplyFilterData.Department;
        } else {
          SetOpenUnarchiveFilterScreen(false);
          unarchivedUserFilterData.Name = unarchivedUserApplyFilterData.Name;
          unarchivedUserFilterData.JobTitle = unarchivedUserApplyFilterData.JobTitle;
          unarchivedUserFilterData.Office = unarchivedUserApplyFilterData.Office;
          unarchivedUserFilterData.Department = unarchivedUserApplyFilterData.Department;
        }
      }
    }
  }

  return (
    <>
      <div>
        {!archivedUsersScreen ? (
          <Tabs type="underline" accent="teal" defaultValue="ArchivedUsers"
            onChange={OnTabChange}>
            <TabHeaders>
              <TabHeader value="ArchivedUsers">Archived Users</TabHeader>
              <TabHeader value="UnarchivedUsers">Unarchived Users</TabHeader>
            </TabHeaders>
            <TabContent value="ArchivedUsers" style={{ paddingTop: '10px' }}>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openArchiveduserTabScreen ? (
                    <>
                      {/*filter_Btn" */}
                      <div className="fr mt_10 mb_10">
                        <SquareButton
                          icon={<MicrosoftExcelFilled />}
                          accent="teal"
                          onClick={ExportAllArchiveUsers}
                          style={{ marginRight: "5px" }} />
                        <Button
                          icon={<SwitchesFilled />}
                          text="Filter"
                          onClick={OnArchiveFilterClick}
                          badge={archiveUsersFilterCount}
                          badgeAccent='teal'
                        />
                      </div>
                      <div>
                        <DataTable columns={archiveUsercolumns} noDataElement={<P>Sorry, there is no data here.</P>} data={allArchivedUsersData} />
                      </div>
                      <Pager
                        page={page}
                        pageCount={pageCount}
                        showFirstLast
                        onPageRequest={handleArchiveUserPageChangeRequest}
                        style={{ justifyContent: 'flex-end', padding: '10px', borderBottom: '1px solid #e2ecef' }}
                      />
                      <div className="fr mt_10 mb_10">
                        <Button
                          text="Unarchive"
                          size="large"
                          minWidth="100px"
                          accent="teal"
                          disabled={isdisabled}
                          onClick={OnUnarchiveClick}
                        />
                      </div>
                      {/* Filter Model for Archive User*/}
                      <div>
                        <Modal
                          visible={openArchiveFilterScreen}
                          heading="Archive Users Filter"
                          width="small"
                          accent="teal"
                          onClose={() => { resetArchiveUnarchiveUserDetailFilterOnClick("close", "fromArchiveUserFilter") }}
                          primaryButtonProps={{ text: "Apply", accent: "teal" }}
                          onPrimaryButtonClick={OnArchiveFilterApplyClick}
                          cancelButtonProps={{ text: "Clear" }}
                          onCancelButtonClick={() => { resetArchiveUnarchiveUserDetailFilterOnClick("clear", "fromArchiveUserFilter") }}
                        >
                          <FormLayout columns={2} rows={4}>
                            <FormField
                              label="User Name"
                              editor={<Input name="usr-name" value={archivedUserFilterData.Name} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Job Title"
                              editor={<Input name="usr-jobtitle" value={archivedUserFilterData.JobTitle} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Office"
                              editor={<Input name="usr-office" value={archivedUserFilterData.Office} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Department"
                              editor={<Input name="usr-department" value={archivedUserFilterData.Department} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                          </FormLayout>
                        </Modal>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </TabContent>
            <TabContent value="UnarchivedUsers" style={{ paddingTop: '10px' }}>
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <div>
                  {openUnarchiveduserTabScreen ? (
                    <>
                      {/*filter_Btn" */}
                      <div className="fr mt_10 mb_10">
                        <SquareButton
                          icon={<MicrosoftExcelFilled />}
                          accent="teal"
                          onClick={ExportAllUnarchiveUsers}
                          style={{ marginRight: "5px" }} />
                        <Button
                          icon={<SwitchesFilled />}
                          text="Filter"
                          onClick={OnUnarchiveFilterClick}
                          badge={unarchiveUsersFilterCount}
                          badgeAccent='teal'
                        />
                      </div>
                      <div>
                        <DataTable columns={unArchiveUsercolumns} noDataElement={<P>Sorry, there is no data here.</P>} data={allUnarchivedUsersData} />
                      </div>
                      <Pager
                        showFirstLast
                        page={page}
                        pageCount={pageCount}
                        onPageRequest={handleUnArchiveUserPageChangeRequest}
                        style={{ justifyContent: 'flex-end', padding: '10px', borderBottom: '1px solid #e2ecef' }}
                      />
                      <div className="fr mt_10 mb_10">
                        <Button
                          text="Archive"
                          size="large"
                          minWidth="100px"
                          accent="teal"
                          disabled={isdisabled}
                          onButtonClick={OnArchiveClick}
                        />
                      </div>
                      {/* Filter Model for Unarchive User */}
                      <div>
                        <Modal
                          visible={openUnarchiveFilterScreen}
                          heading="Unarchive Users Filter"
                          width="small"
                          accent="teal"
                          onClose={() => { resetArchiveUnarchiveUserDetailFilterOnClick("close", "fromUnArchiveUserFilter") }}
                          primaryButtonProps={{ text: "Apply", accent: "teal" }}
                          onPrimaryButtonClick={OnUnarchiveFilterApplyClick}
                          cancelButtonProps={{ text: "Clear" }}
                          onCancelButtonClick={() => { resetArchiveUnarchiveUserDetailFilterOnClick("clear", "fromUnArchiveUserFilter") }}
                        >
                          <FormLayout columns={2} rows={4}>
                            <FormField
                              label="User Name"
                              editor={<Input name="un-usr-name" value={unarchivedUserFilterData.Name} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Job Title"
                              editor={<Input name="un-usr-jobtitle" value={unarchivedUserFilterData.JobTitle} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Office"
                              editor={<Input name="un-usr-office" value={unarchivedUserFilterData.Office} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                            <FormField
                              label="Department"
                              editor={<Input name="un-usr-department" value={unarchivedUserFilterData.Department} onChange={handleTextChange} style={{ width: '100%' }} />}
                            />
                          </FormLayout>
                        </Modal>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </TabContent>
          </Tabs>
        ) : null}

        {archivedUsersScreen ? (
          <>
            <div>
              {openArchiveduserTabScreen ? (
                <UserDetails
                  openDetailScreen={openDetailScreen}
                  SetopenUserScreen={SetopenArchivedUsersScreen}
                  SetopenopenDetailScreen={SetopenopenDetailScreen}
                  openuserScreen={archivedUsersScreen}
                  onParentRowClick={onArchiveRowClick}
                  disabledSave={true}
                  disabledStartAutoCheck={true}
                  disabledStopAutoCheck={true}
                />
              ) : null}
            </div>
            <div>
              {openUnarchiveduserTabScreen ? (
                <UserDetails
                  openDetailScreen={openDetailScreen}
                  SetopenUserScreen={SetopenArchivedUsersScreen}
                  SetopenopenDetailScreen={SetopenopenDetailScreen}
                  openuserScreen={archivedUsersScreen}
                  onParentRowClick={onUnArchiveRowClick}
                  disabledSave={false}
                  disabledStartAutoCheck={false}
                  disabledStopAutoCheck={false}
                />
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      <div>
        {showArchiveUserConfirmationPopup ? (
          <Confirm
            visible type="information" heading="Confirm"
            onPrimaryButtonClick={onBtnConfirmationSaveClick}
            onCancelButtonClick={onBtnConfirmationCancelClick}
            text={showArchiveUserConfirmationDialogue ? "Are you sure you want to archive this user?" : "Are you sure you want to archive these user?"}
            onClose={() => setshowArchiveUserConfirmationPopup(false)}
          />
        ) : null}
      </div>
    </>
  );
}

export default ArchivedUsers;