/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ILicenceData } from "../core/view-Model/userdetails.model";
import { Span } from "@tag/tag-components-react-v4";
import "../assets/css/userDetail.scss";

const Offences: React.FC<{ obj: ILicenceData }> = (props) => {
  const [offecesStartDate, SetoffecesStartDate] = useState(Date);
  const [offecesExpirytDate, SetoffecesExpirytDate] = useState(Date);
  const LicenceValidation = () => {
    if (props.obj.doC_DVLA_L_O_STARTDATE != null) {
      var offencesStartDate = new Date(props.obj.doC_DVLA_L_O_STARTDATE);
      var ddmmyyyOffenceStartDate =
        offencesStartDate.getDate() +
        "/" +
        (offencesStartDate.getMonth() + 1) +
        "/" +
        offencesStartDate.getFullYear();
      SetoffecesStartDate(ddmmyyyOffenceStartDate);
    } else {
      SetoffecesStartDate("-");
    }

    if (props.obj.doC_DVLA_L_O_EXPIRYDATE != null) {
      var offencesExpiryDate = new Date(props.obj.doC_DVLA_L_O_EXPIRYDATE);
      var ddmmyyymotoffencesExpiryDate =
        offencesExpiryDate.getDate() +
        "/" +
        (offencesExpiryDate.getMonth() + 1) +
        "/" +
        offencesExpiryDate.getFullYear();
      SetoffecesExpirytDate(ddmmyyymotoffencesExpiryDate);
    } else {
      SetoffecesExpirytDate("-");
    }
  };
  React.useEffect(() => {
    LicenceValidation();
  }, [props]);

  return (
    <tr>
      <td>
        <Span style={{ fontSize: "12px",  width: "80px" }}>{props.obj.doC_DVLA_L_O_NAME}</Span>
      </td>
      <td>
        <Span style={{ fontSize: "12px",  width: "30px" }}>{props.obj.doC_DVLA_L_O_POINTS}</Span>
      </td>
      <td>
        <Span style={{ fontSize: "12px",  width: "80px" }}>{offecesStartDate}</Span>
      </td>
      <td>
        <Span style={{ fontSize: "12px",  width: "80px" }}>{offecesExpirytDate}</Span>
      </td>
      <td>
        <Span style={{ fontSize: "12px",  width: "110px" }}>{props.obj.doC_DVLA_L_O_LEGALLITERAL}</Span>
      </td>
    </tr>
  );
};

export default Offences;
